// src/components/organisms/BarSection.jsx
import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import TicketItem from '../../molecules/TicketItem/TicketItem'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as EditIcon } from '../../../assets/mango/icons/edit.svg'

const BarSection = ({ sx = {} }) => {
  const [selectedBarItemIndex, setSelectedBarItemIndex] = useState(0)
  const handleBarItemHover = (index) => {
    setSelectedBarItemIndex(index)
  }
  const barItems = [
    {
      id: 1,
      name: 'Pisco + Bebida',
      description: 'Alto del Carmen 35º',
      price: '$7.000',
    },
    {
      id: 2,
      name: 'Gin + Bebida',
      description: 'Bombay',
      price: '$7.000',
    },
    {
      id: 3,
      name: 'Vodka + Bebida',
      description: 'Absolut Vodka',
      price: '$7.000',
    },
    {
      id: 4,
      name: 'Vodka + Bebida',
      description: 'Absolut Vodka',
      price: '$7.000',
    },
  ]

  return (
    <Box
      sx={{
        borderRadius: '18px',
        backgroundColor: '#07181e',
        width: { xs: '100%', md: '839px' },
        maxHeight: '583px',
        display: 'flex',
        flexDirection: 'column',
        ...sx,
      }}
    >
      {/* Título de la sección */}
      <Box
        sx={{
          borderRadius: '18px',
          padding: '21px 21px 0px', // Ajustamos el padding superior e inferior
          backgroundColor: 'inherit',
          position: 'sticky',
          top: 0,
          zIndex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h2" sx={{ color: '#fff', marginBottom: '24px' }}>
          Barra
        </Typography>
        <IconButton
          aria-label="Editar Tickets"
          sx={{ color: '#fff', marginBottom: '24px' }}
          onClick={() => {
            // Aquí puedes agregar la lógica para editar
          }}
        >
          <EditIcon />
        </IconButton>
      </Box>

      {/* Contenedor de los items con scroll */}
      <Box
        sx={{
          padding: '0px 21px 21px', // Ajustamos el padding lateral e inferior
          overflowY: 'auto',
        }}
      >
        {barItems.map((item, index) => (
          <TicketItem
            key={item.id}
            name={item.name}
            description={item.description}
            price={item.price}
            selected={selectedBarItemIndex === index}
            onMouseEnter={() => handleBarItemHover(index)}
          />
        ))}
      </Box>
    </Box>
  )
}

export default BarSection
