import React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import AddCircleIcon from '../../atoms/AddCircleIcon/AddCircleIcon'
import RemoveCircleIcon from '../../atoms/RemoveCircleIcon/RemoveCircleIcon'

const iconStyles = {
  height: '20px',
  width: '20px',
}

const TicketControls = ({ count, onIncrement, onDecrement }) => (
  <Box display="flex" alignItems="center">
    <IconButton
      onClick={onDecrement}
      sx={{
        padding: 0,
        marginRight: 1,
      }}
    >
      <RemoveCircleIcon style={iconStyles} />
    </IconButton>
    <Typography variant="body1">{count}</Typography>
    <IconButton
      onClick={onIncrement}
      sx={{
        padding: 0,
        marginLeft: 1,
      }}
    >
      <AddCircleIcon style={iconStyles} />
    </IconButton>
  </Box>
)

export default TicketControls
