import React, { useCallback, useState } from 'react'
import { Grid, IconButton } from '@mui/material'
import { ReactComponent as AddCircleIcon } from '../../../assets/mango/icons/add-circle-outline.svg'
import TicketFormItem from '../TicketFormItem/TicketFormItem'
import { Typography } from '../../atoms'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

const TicketForm = ({ tickets, setTickets, nextTicketId, setNextTicketId }) => {
  const [expandedTicketId, setExpandedTicketId] = useState(tickets.length > 0 ? tickets[0].id : null)

  const handleAddTicket = () => {
    const newTicketId = nextTicketId
    const newTicket = { id: newTicketId, data: {}, priority: tickets.length }
    setTickets([...tickets, newTicket])
    setNextTicketId(nextTicketId + 1)
    setExpandedTicketId(newTicketId)
  }

  const handleDeleteTicket = (ticketId) => {
    const newTickets = tickets.filter((ticket) => ticket.id !== ticketId)
    setTickets(newTickets)
    if (expandedTicketId === ticketId) {
      setExpandedTicketId(null)
    }
  }

  const handleDragEnd = (result) => {
    if (!result.destination) return
    const newTickets = Array.from(tickets)
    const [movedTicket] = newTickets.splice(result.source.index, 1)
    newTickets.splice(result.destination.index, 0, movedTicket)
    const updatedTickets = newTickets.map((ticket, index) => ({
      ...ticket,
      priority: index,
    }))
    setTickets(updatedTickets)
  }

  const setTicketDataAtIndex = useCallback(
    (index) => (data) => {
      setTickets((prevTickets) => {
        const newTickets = [...prevTickets]
        newTickets[index] = { ...newTickets[index], data }
        return newTickets
      })
    },
    [setTickets]
  )

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="tickets">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {tickets.map((ticket, index) => (
                <Draggable key={ticket.id} draggableId={`ticket-${ticket.id}`} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={{ ...provided.draggableProps.style, marginBottom: '16px' }}
                    >
                      <TicketFormItem
                        key={ticket.id}
                        ticketNumber={ticket.id}
                        ticketData={ticket.data}
                        setTicketData={setTicketDataAtIndex(index)}
                        expanded={expandedTicketId === ticket.id}
                        onChange={(isExpanded) => {
                          setExpandedTicketId(isExpanded ? ticket.id : null)
                        }}
                        onDelete={() => handleDeleteTicket(ticket.id)}
                        dragHandleProps={provided.dragHandleProps}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {/* Nueva área para "Agregar nuevo Ticket" */}
      <Grid
        container
        width="100%"
        border="1px dashed black"
        sx={{
          borderRadius: '10px',
          marginTop: '16px',
          '&::before': { display: 'none' },
          padding: '8px 16px',
        }}
      >
        <Grid container alignItems="center" sx={{ minHeight: '48px' }}>
          <Grid item xs={6}>
            <Typography>Agregar otro Ticket</Typography>
          </Grid>
          <Grid item xs={6} container justifyContent="flex-end" alignItems="center">
            <IconButton onClick={handleAddTicket}>
              <AddCircleIcon style={{ height: '32px', width: '32px', color: '#fb8c00' }} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default TicketForm
