import React from 'react'
import { ReactComponent as AddCircleSvg } from '../../../assets/mango/icons/add-circle-outline.svg'

const AddCircleIcon = (props) => (
  <AddCircleSvg
    {...props}
    style={{
      height: '20px',
      width: '20px',
      color: '#fb8c00',
      display: 'block',
    }}
  />
)

export default AddCircleIcon
