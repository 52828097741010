import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import Icon from '@mui/material/Icon'

import { Box, Typography } from 'components/atoms'
import typography from 'assets/theme/base/typography'

function Footer({ light = false }) {
  const { size } = typography
  const { pathname } = useLocation()

  return (
    <Box position={pathname !== '/auth/Login' ? 'absolute' : 'relative'} width="100%" bottom={0} py={4}>
      <Container>
        <Box
          width="100%"
          display="flex"
          flexDirection={{ xs: 'column', lg: 'row' }}
          justifyContent="space-between"
          alignItems="center"
          px={1.5}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            color={light ? 'white' : 'text'}
            fontSize={size.sm}
          >
            &copy; {new Date().getFullYear()}, made with
            <Box fontSize={size.md} color={light ? 'white' : 'dark'} mb={-0.5} mx={0.25}>
              <Icon color="inherit" fontSize="inherit">
                favorite
              </Icon>
            </Box>
            by
            <Link href="https://www.mangoticket.com" target="_blank">
              <Typography variant="button" fontWeight="medium" color={light ? 'white' : 'dark'}>
                &nbsp;Mango Ticket&nbsp;
              </Typography>
            </Link>
          </Box>
          <Box
            component="ul"
            sx={({ breakpoints }) => ({
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'center',
              listStyle: 'none',
              mt: 3,
              mb: 0,
              p: 0,

              [breakpoints.up('lg')]: {
                mt: 0,
              },
            })}
          >
            <Box component="li" pr={2} lineHeight={1}>
              <Link href="https://www.mangoticket.com" target="_blank">
                <Typography variant="button" fontWeight="regular" color={light ? 'white' : 'dark'}>
                  Mango Ticket
                </Typography>
              </Link>
            </Box>
            <Box component="li" px={2} lineHeight={1}>
              <Link href="https://www.mangoticket.com/presentation" target="_blank">
                <Typography variant="button" fontWeight="regular" color={light ? 'white' : 'dark'}>
                  About Us
                </Typography>
              </Link>
            </Box>
            <Box component="li" px={2} lineHeight={1}>
              <Link href="https://www.mangoticket.com/blog" target="_blank">
                <Typography variant="button" fontWeight="regular" color={light ? 'white' : 'dark'}>
                  Blog
                </Typography>
              </Link>
            </Box>
            <Box component="li" pl={2} lineHeight={1}>
              <Link href="https://www.mangoticket.com/license" target="_blank">
                <Typography variant="button" fontWeight="regular" color={light ? 'white' : 'dark'}>
                  License
                </Typography>
              </Link>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

Footer.propTypes = {
  light: PropTypes.bool,
}

export default Footer
