// src/components/SendCourtesiesDialog/SendCourtesiesDialog.js

import React, { useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  useMediaQuery,
} from '@mui/material'
import { useEventsData } from '../../../hooks/userManagement/useEventsData'
import { useSendCourtesies } from '../../../hooks/userManagement/useSendCourtesies'

const SendCourtesiesDialog = ({ open, onClose, selectedUserIds }) => {
  const { eventsData } = useEventsData()
  const { sendCourtesies } = useSendCourtesies()
  const [selectedEvent, setSelectedEvent] = useState('')

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const handleSend = async () => {
    if (!selectedEvent) {
      alert('Por favor, selecciona un evento.')
      return
    }

    try {
      await sendCourtesies(selectedUserIds, selectedEvent)
      alert('Cortesías enviadas exitosamente.')
      onClose()
      setSelectedEvent('')
    } catch (error) {
      console.error(error)
      alert('Ocurrió un error al enviar las cortesías.')
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: '#092838',
          borderRadius: '0.5rem',
        },
      }}
    >
      <DialogTitle sx={{ backgroundColor: '#092838', color: '#fff' }}>
        Enviar Cortesías
        <Box
          mt={1}
          sx={{
            flexShrink: 0,
            width: '100%',
            height: '1px',
            backgroundColor: '#041E2B !important',
          }}
        />
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: '#092838', color: '#fff' }}>
        <TextField
          select
          label="Selecciona un Evento"
          value={selectedEvent}
          onChange={(e) => setSelectedEvent(e.target.value)}
          variant="outlined"
          fullWidth
          sx={{
            marginBottom: 2,
            '& .MuiInputLabel-root': {
              color: '#fff',
            },
            '& .MuiInputBase-root': {
              color: '#fff',
              backgroundColor: '#041E2B',
              borderRadius: '8px',
              height: '50px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#718EBF',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#F79009',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#F79009',
            },
            '& .MuiSvgIcon-root': {
              color: '#fff',
            },
          }}
          InputLabelProps={{
            sx: {
              '&.Mui-focused': {
                color: '#F79009',
              },
            },
          }}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  backgroundColor: '#092838',
                  color: '#fff',
                },
              },
            },
            sx: {
              paddingTop: '12px',
            },
          }}
        >
          {eventsData.map((event) => (
            <MenuItem key={event.id} value={event.id}>
              {event.name}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: '#092838' }}>
        <Button onClick={onClose} sx={{ color: '#F79009' }}>
          Cancelar
        </Button>
        <Button
          onClick={handleSend}
          variant="contained"
          sx={{
            backgroundColor: '#F79009',
            color: 'white',
            borderRadius: '8px',
            fontWeight: 'bold',
          }}
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SendCourtesiesDialog
