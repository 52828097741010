import PropTypes from 'prop-types'

import { Box, Typography } from 'components/atoms'
import React from 'react'
import money from '../../../assets/mango/icons/money.png'
import mango from '../../../assets/mango/icons/mango.png'
import ticket from '../../../assets/mango/icons/ticket.png'

// format currency
function formatCurrency(value) {
  return '$' + value.toLocaleString().replaceAll(',', '.')
}

function formatPercentage(value) {
  return `${value}%`
}

//thousands number format
function formatNumber(value) {
  return value.toLocaleString().replaceAll(',', '.')
}

function formatValue(value, format) {
  if (value === 0) {
    return '-'
  }
  if (format === 'PERCENTAGE') return formatPercentage(value)
  if (format === 'NUMBER') return formatNumber(value)
  return formatCurrency(value)
}

function getIcon(icon) {
  switch (icon) {
    case 'money':
      return money
    case 'mango':
      return mango
    case 'ticket':
      return ticket
    default:
      return mango
  }
}

function StatisticInfo({ title, value, format, icon }) {
  return (
    <>
      <Typography variant="body2" color="black">
        {title}
      </Typography>
      <Box display="flex" flexDirection="row" justifyContent="space-between" mt={1} alignItems="center">
        <Typography variant="h4" alignItems="flex-start">
          {formatValue(value, format)}
        </Typography>
        <img src={getIcon(icon)} alt="profile-image" style={{ width: '40px', paddingBottom: '3px' }} />
      </Box>
    </>
  )
}

StatisticInfo.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default StatisticInfo
