// src/components/UserTable/UserTable.js

import React from 'react'
import {
  Box,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { ReactComponent as DownArrow } from '../../../assets/mango/icons/chevron-down-outline.svg'
import UserTableRow from '../UserTableRow/UserTableRow'
import { LoadingSpinner } from '../../atoms'

const UserTable = ({
  users,
  onEditUser,
  refetchUsers,
  isSmallScreen,
  selectedUsers,
  onSelectUser,
  onSelectAllUsers,
}) => {
  if (!users) return <LoadingSpinner />

  const isAllSelected = users.length > 0 && selectedUsers.length === users.length

  const handleSelectAllClick = () => {
    onSelectAllUsers(!isAllSelected)
  }

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{
        width: '100%',
        backgroundColor: '#092838',
        borderRadius: '0',
        boxShadow: 'none',
        border: 'none',
      }}
    >
      <Table>
        <TableHead sx={{ display: 'table-header-group' }}>
          <TableRow>
            {/* Checkbox en el encabezado */}
            <TableCell padding="checkbox" style={{ borderBottom: 'none' }}>
              <Checkbox
                color="primary"
                indeterminate={selectedUsers.length > 0 && selectedUsers.length < users.length}
                checked={isAllSelected}
                onChange={handleSelectAllClick}
              />
            </TableCell>
            {users.length
              ? ['Nombre', 'Correo'].map((header) => (
                  <TableCell
                    key={header}
                    style={{ paddingBottom: 8, borderBottom: 'none' }}
                    sx={{ display: isSmallScreen && header === 'Correo' ? 'none' : 'table-cell' }}
                  >
                    <Box display="flex" alignItems="center">
                      <Typography variant="h6" fontWeight="bold" color="white !important">
                        {header}
                      </Typography>
                      <IconButton>
                        <DownArrow style={{ height: '15px', width: '15px', color: '#718EBF' }} />
                      </IconButton>
                    </Box>
                  </TableCell>
                ))
              : null}
            {users.length ? (
              <TableCell style={{ paddingBottom: 8, borderBottom: 'none', textAlign: 'center' }}>
                <Typography variant="h6" fontWeight="bold" color="white !important">
                  Acciones
                </Typography>
              </TableCell>
            ) : null}
          </TableRow>
          <TableRow>
            <TableCell colSpan={4} style={{ padding: 0, borderBottom: 'none' }}>
              <Box
                sx={{
                  flexShrink: 0,
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#667085 !important',
                }}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <UserTableRow
              key={user.id}
              user={user}
              onEdit={onEditUser}
              refetchUsers={refetchUsers}
              isSmallScreen={isSmallScreen}
              isSelected={selectedUsers.includes(user.id)}
              onSelectUser={onSelectUser}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default UserTable
