import React from 'react'
import { Box, Button, Step, StepLabel, Stepper, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { BarForm, EventForm, EventPreview, TicketForm, TicketPreview } from '../../organisms'
import { FormButton } from '../../atoms'
import Divider from '@mui/material/Divider'
import MobilePhone from '../../organisms/MobilePhone/MobilePhone'
import BarPreview from '../../organisms/BarPreview/BarPreview'
import EventDetails from '../../organisms/EventDetails/EventDetails'
import CombinedPreview from '../../organisms/CombinedPreview/CombinedPreview'
import useCreateEvent from '../../../hooks/events/useCreateEvent'
import { formatISO, parse } from 'date-fns'

const steps = ['Datos del Evento', 'Tickets', 'Barra', 'Detalles']

function setStepColor(step, index) {
  if (step > index) {
    return '#4CAF50 !important'
  } else if (step === index) {
    return '#FFA726'
  } else {
    return '#BDBDBD'
  }
}

const EventFormTemplate = ({
  data,
  setData,
  step,
  setStep,
  tickets,
  setTickets,
  nextTicketId,
  setNextTicketId,
  bar,
  setBar,
}) => {
  const [event, setEvent] = React.useState({ event: null, tickets: null, bar: null })
  const { loading, error, completed } = useCreateEvent(event)
  const navigate = useNavigate()

  const [eventCreated, setEventCreated] = React.useState(false)

  const renderEventForm = () => {
    if (eventCreated) {
      return renderConfirmationScreen()
    }

    switch (step) {
      case 0:
        return <EventForm data={data} setData={setData} />
      case 1:
        return (
          <TicketForm
            tickets={tickets}
            setTickets={setTickets}
            nextTicketId={nextTicketId}
            setNextTicketId={setNextTicketId}
          />
        )
      case 2:
        return <BarForm bar={bar} setBar={setBar} />
      case 3:
        return <EventDetails data={data} tickets={tickets} bar={bar} setStep={setStep} />
      default:
        return <EventForm data={data} setData={setData} />
    }
  }

  const renderPreview = () => {
    if (eventCreated) {
      return null
    }

    switch (step) {
      case 0:
        return <EventPreview data={data} />
      case 1:
        return <TicketPreview tickets={tickets} setTickets={setTickets} />
      case 2:
        return <BarPreview bar={bar} setBar={setBar} />
      case 3:
        return <CombinedPreview data={data} tickets={tickets} bar={bar} />
      default:
        return <EventPreview data={data} />
    }
  }

  const formatDate = (date) => {
    const parsedDate = parse(date, 'dd-MM-yyyy', new Date())
    return formatISO(parsedDate)
  }

  const validateEventData = () => {
    const combineDateAndTime = (dateStr, timeStr) => {
      const [day, month, year] = dateStr.split('-').map(Number)
      const [hours, minutes] = timeStr.split(':').map(Number)

      return new Date(year, month - 1, day, hours, minutes)
    }

    const startDateTime = combineDateAndTime(data.startDate, data.startHour)
    const endDateTime = combineDateAndTime(data.endDate, data.endHour)

    if (!data.name.trim()) {
      return 'El nombre del evento es requerido.'
    }
    if (!data.address.trim()) {
      return 'La dirección del evento es requerida.'
    }
    if (!data.venue.trim()) {
      return 'El lugar del evento es requerido.'
    }
    if (!data.description.trim()) {
      return 'La descripción del evento es requerida.'
    }
    if (data.description.length < 10) {
      return 'La descripción del evento debe tener al menos 10 caracteres.'
    }
    if (!data.startDate.trim()) {
      return 'La fecha de inicio del evento es requerida.'
    }
    if (!data.startHour.trim()) {
      return 'La hora de inicio del evento es requerida.'
    }
    if (!data.endDate.trim()) {
      return 'La fecha de finalización del evento es requerida.'
    }
    if (!data.endHour.trim()) {
      return 'La hora de finalización del evento es requerida.'
    }
    if (startDateTime > endDateTime) {
      return 'La fecha y hora de inicio no pueden ser posteriores a la fecha y hora de finalización.'
    }
    if (startDateTime.getTime() === endDateTime.getTime()) {
      return 'La fecha y hora de inicio no pueden ser iguales a la fecha y hora de finalización.'
    }
    if (!data.image) {
      return 'La imagen del evento es requerida.'
    }
    return ''
  }

  const handleCreateEvent = () => {
    if (tickets.length === 0) {
      alert('Debes agregar al menos un ticket.')
      return
    }

    const eventData = {
      name: data.name,
      place: data.venue,
      description: data.description,
      image: data.image,
      start_at: formatDate(data.startDate),
      end_at: formatDate(data.endDate),
      start_hour: data.startHour,
      end_hour: data.endHour,
      nominated: data.nominated,
      address: data.address,
    }

    const ticketItems = tickets.map((ticket, index) => ({
      name: ticket.data.name,
      description: ticket.description || '',
      type: 'ENTRANCE',
      priority: ticket.priority,
      stock: ticket.data.quantity,
      max_per_sale: ticket.data.maxPerSale,
      price: ticket.data.price,
      resale: false,
      end_at: ticket.data.endDate || '',
      end_hour: ticket.data.endHour || '',
    }))

    const barItems = bar.map((item, index) => ({
      name: item.data.name,
      description: item.data.description || '',
      type: 'DRINK',
      priority: item.priority,
      stock: item.data.quantity,
      max_per_sale: 999,
      price: item.data.price,
      resale: false,
      end_at: item.data.endDate || '',
      end_hour: item.data.endHour || '',
      image: item.data.image || null,
    }))

    setEvent({ event: eventData, tickets: ticketItems, bar: barItems })

    setEventCreated(true)
  }

  const handleGoToDashboard = () => {
    navigate('/events')
  }

  const renderConfirmationScreen = () => {
    return (
      <Box
        sx={{
          textAlign: 'center',
          mt: 4,
          width: '100%',
        }}
      >
        {/* Imagen del emoticono */}
        <img src="/assets/images/img.png" alt="Éxito" width={140} height={130} />
        {/* Texto principal */}
        <Typography variant="h4" sx={{ mt: 2 }}>
          Solo falta un paso
        </Typography>
        {/* Texto secundario */}
        <Typography variant="body1" sx={{ mt: 1 }}>
          Te contactaremos a la brevedad para publicar tu evento.
        </Typography>
        {/* Botón para ir al Dashboard */}
        <Button variant="contained" color="primary" sx={{ mt: 3, textTransform: 'none' }} onClick={handleGoToDashboard}>
          Ir al Dashboard
        </Button>
      </Box>
    )
  }

  const handleStepChange = (newStep) => {
    if (eventCreated) {
      return
    }

    if (newStep === 1) {
      const errorMessage = validateEventData()
      if (errorMessage) {
        alert(errorMessage)
        return
      }
    }
    if (step === 1 && newStep > 1) {
      if (tickets.length === 0) {
        alert('Debes agregar al menos un ticket.')
        return
      }
      const incompleteTickets = tickets.some(
        (ticket) => !ticket.data.name || !ticket.data.price || !ticket.data.quantity
      )
      if (incompleteTickets) {
        alert('Todos los tickets deben tener Nombre, Precio y Cantidad.')
        return
      }
    }
    if (step === 2 && newStep > 2) {
      if (bar.length > 0) {
        const incompleteBarItems = bar.some((item) => !item.data.name || !item.data.price || !item.data.quantity)
        if (incompleteBarItems) {
          alert('Todos los tragos deben tener Nombre, Precio y Cantidad.')
          return
        }
      }
    }
    setStep(newStep)
  }

  const currentStep = eventCreated ? steps.length : step

  return (
    <Box
      sx={{
        p: { xs: 1, sm: 2, md: 3 },
        bgcolor: 'background.paper',
        color: 'text.primary',
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 100px)',
      }}
    >
      {/* Stepper Navigation */}
      <Stepper activeStep={currentStep} sx={{ mb: 2, mt: 3, justifyContent: 'flex-start', pl: 2 }}>
        {steps.map((label, index) => (
          <Step key={index} completed={eventCreated || index < step}>
            <StepLabel
              StepIconProps={{
                sx: {
                  fontSize: index === step ? '32px' : '24px',
                  color: eventCreated || index < step ? '#4CAF50 !important' : index === step ? '#FFA726' : '#BDBDBD',
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      {/* Main Content */}
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          overflow: 'hidden',
          maxWidth: '100%',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        {/* Form Area */}
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            pr: { md: 2 },
            maxHeight: 'calc(100vh - 320px) !important',
          }}
        >
          <Box
            sx={{
              flex: 1,
              overflowY: 'auto',
              p: { xs: 1, sm: 2, md: 3 },
              borderRadius: 2,
              boxShadow: 'none',
            }}
          >
            {renderEventForm()}
          </Box>
        </Box>

        {/* Preview Area */}
        {!eventCreated && (
          <Box
            sx={{
              width: { xs: '100%', md: '300px' },
              ml: { md: 2 },
              mt: { xs: 2, md: 4 },
              alignSelf: { xs: 'center', md: 'flex-start' },
              flexShrink: 0,
            }}
          >
            <MobilePhone>
              <Box
                sx={{
                  backgroundColor: '#092838 !important',
                  maxHeight: '460px',
                  maxWidth: '240px',
                  minHeight: '460px',
                  minWidth: '240px',
                  overflowY: 'auto',
                }}
              >
                {renderPreview()}
              </Box>
            </MobilePhone>
          </Box>
        )}
      </Box>

      {/* Divider */}
      {!eventCreated && (
        <Box mt={2} mb={2}>
          <Divider
            sx={{
              height: '1px',
              border: 'none',
              backgroundColor: '#E0E0E0',
              boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.1)',
            }}
          />
        </Box>
      )}

      {/* Footer Buttons */}
      {!eventCreated && (
        <Box display="flex" justifyContent="flex-end" mt={2}>
          {step > 0 && (
            <FormButton
              sx={{
                backgroundColor: '#FFF7E5',
                color: '#FFA726',
                '&:hover': { backgroundColor: '#FFEDC2' },
                boxShadow: 'none',
                borderRadius: '8px',
                textTransform: 'none',
                mr: 2,
                padding: '8px 16px',
              }}
              onClick={() => handleStepChange(step - 1)}
            >
              Volver
            </FormButton>
          )}
          {step < steps.length - 1 ? (
            <FormButton
              sx={{
                backgroundColor: '#FFA726',
                color: '#fff',
                '&:hover': { backgroundColor: '#FF9800' },
                boxShadow: 'none',
                borderRadius: '8px',
                textTransform: 'none',
                padding: '8px 16px',
              }}
              onClick={() => handleStepChange(step + 1)}
            >
              Continuar
            </FormButton>
          ) : (
            <FormButton
              sx={{
                backgroundColor: '#FFA726 !important',
                color: '#fff',
                '&:hover': { backgroundColor: '#FFA726' },
                boxShadow: 'none',
                borderRadius: '8px',
                textTransform: 'none',
                padding: '8px 16px',
              }}
              onClick={handleCreateEvent}
            >
              Crear Evento
            </FormButton>
          )}
        </Box>
      )}
    </Box>
  )
}

export default EventFormTemplate
