// src/hooks/useUpdateUser.js

import { usersInMemory } from '../memoryDataStore'

export const useUpdateUser = () => {
  const updateUser = async (userId, userData) => {
    const index = usersInMemory.findIndex((user) => user.id === userId)
    if (index !== -1) {
      usersInMemory[index] = { ...usersInMemory[index], ...userData }
    } else {
      throw new Error('Usuario no encontrado')
    }
  }

  return { updateUser }
}
