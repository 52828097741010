// FAQ.js
import React, { forwardRef, useState } from 'react'
import { Box, Button, Container, Typography } from '@mui/material'

const faqData = [
  {
    question: '¿Qué es MangoTicket y cómo puede ayudarme en la gestión de eventos?',
    answer:
      'MangoTicket es una plataforma integral que permite a los organizadores de eventos gestionar y vender entradas de manera eficiente. Con herramientas intuitivas, puedes crear eventos, personalizar tus páginas y ofrecer una experiencia única a tus asistentes. Además, puedes gestionar productos de barra y maximizar tus ingresos.',
  },
  {
    question: '¿Es seguro utilizar MangoTicket para gestionar ventas y datos personales?',
    answer:
      'Sí, la seguridad de nuestros usuarios es una prioridad. Implementamos protocolos de encriptación y protección de datos para garantizar transacciones seguras. Puedes confiar en que tu información y la de tus clientes están protegidas.',
  },
  {
    question: '¿Cuánto cuesta utilizar MangoTicket y qué opciones de precios ofrecen?',
    answer:
      'Los costos de MangoTicket varían según el tipo de evento y los servicios que elijas. Ofrecemos diferentes planes para adaptarnos a tus necesidades específicas. Puedes consultar nuestra página de precios para más detalles.',
  },
  {
    question: '¿Puedo personalizar la página de mi evento y los productos en venta?',
    answer:
      'Sí, puedes personalizar todos los aspectos de tu evento, desde la página de venta hasta los productos ofrecidos. Nuestro editor fácil de usar te permite ajustar colores, imágenes y textos. Crea una experiencia única que refleje la esencia de tu evento.',
  },
  {
    question: '¿Qué tipo de soporte ofrece MangoTicket en caso de necesitar ayuda?',
    answer:
      'Ofrecemos soporte técnico a través de chat en vivo y correo electrónico. Nuestro equipo está disponible para ayudarte en cualquier momento, asegurando que tu experiencia sea fluida. No dudes en contactarnos si necesitas asistencia.',
  },
]

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Box
      sx={{
        borderBottom: '1px solid #aaa',
      }}
    >
      <Button
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
        sx={{
          backgroundColor: 'transparent',
          color: '#fff',
          border: 'none',
          fontSize: '18px',
          fontWeight: 600,
          padding: '20px 0',
          width: '100%',
          textAlign: 'left',
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
          textTransform: 'none',
        }}
      >
        {question}
        <Box
          component="img"
          src="https://cdn.builder.io/api/v1/image/assets/8d11d8ff049440d581f10d53cffd98cc/2b7c2613f889593e61927bc13c781de2bada2b1995fa7df9a879d5f8e7f7b4c2?apiKey=8d11d8ff049440d581f10d53cffd98cc&"
          alt={isOpen ? 'Collapse' : 'Expand'}
          sx={{
            width: '32px',
            height: '32px',
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease',
          }}
        />
      </Button>
      {isOpen && (
        <Box
          sx={{
            color: '#aaa',
            fontSize: '16px',
            lineHeight: 1.5,
            paddingBottom: '24px',
          }}
        >
          {answer}
        </Box>
      )}
    </Box>
  )
}

const FAQ = forwardRef((props, ref) => {
  return (
    <Box
      ref={ref}
      component="section"
      sx={{
        backgroundColor: '#041e2b',
        padding: { xs: '100px 20px', md: '112px 64px' },
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            gap: '80px',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: '40px', md: '48px' },
                lineHeight: 1.2,
                marginBottom: '24px',
              }}
            >
              Preguntas
            </Typography>
            <Typography
              sx={{
                color: '#aaa',
                fontSize: '18px',
                marginBottom: '32px',
              }}
            >
              Aquí encontrarás respuestas a las dudas más frecuentes sobre MangoTicket y su funcionamiento.
            </Typography>
            <Button
              sx={{
                backgroundColor: 'transparent',
                color: '#fff',
                border: '1px solid #f79009',
                borderRadius: '10px',
                padding: '12px 24px',
                fontSize: '16px',
                cursor: 'pointer',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'rgba(247, 144, 9, 0.1)',
                },
              }}
            >
              Contáctanos
            </Button>
          </Box>
          <Box sx={{ flex: 1 }}>
            {faqData.map((item, index) => (
              <FAQItem key={index} question={item.question} answer={item.answer} />
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  )
})

export default FAQ
