// Testimonial.js
import React from 'react'
import { Box, Container, Typography } from '@mui/material'

const Testimonial = () => {
  return (
    <Box
      component="section"
      sx={{
        backgroundColor: '#fff',
        padding: '112px 64px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Container maxWidth="md">
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: '4px',
              marginBottom: '32px',
            }}
          >
            {[...Array(5)].map((_, index) => (
              <Box
                key={index}
                component="img"
                src={`http://b.io/ext_${index < 3 ? '9' : '10'}-`}
                alt={`${index < 3 ? 'Filled' : 'Empty'} star`}
                sx={{
                  width: '20px',
                  height: '20px',
                }}
              />
            ))}
          </Box>
          <Typography
            component="blockquote"
            sx={{
              color: '#eee',
              fontSize: '24px',
              lineHeight: 1.4,
              marginBottom: '32px',
            }}
          >
            "MangoTicket transformó nuestra manera de gestionar eventos. La plataforma es intuitiva y nos permitió
            vender entradas de manera eficiente."
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '20px',
            }}
          >
            <Box
              component="img"
              src="https://cdn.builder.io/api/v1/image/assets/8d11d8ff049440d581f10d53cffd98cc/388a879be1c97033e3db5cfe6b0f4889ec30f7da30ff7d804463debc1657d05c?apiKey=8d11d8ff049440d581f10d53cffd98cc&"
              alt="Rosario Chacon"
              sx={{
                width: '56px',
                height: '56px',
                borderRadius: '50%',
              }}
            />
            <Box
              sx={{
                textAlign: 'left',
              }}
            >
              <Typography
                sx={{
                  color: '#aaa',
                  fontSize: '16px',
                  fontWeight: 600,
                }}
              >
                Rosario Chacon
              </Typography>
              <Typography
                sx={{
                  color: '#aaa',
                  fontSize: '16px',
                }}
              >
                Sales Manager, Club Chinois
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Testimonial
