// src/hooks/useCreateUsersBatch.js

import { useState } from 'react'
import { usersInMemory } from '../memoryDataStore'
import Papa from 'papaparse'

export const useCreateUsersBatch = () => {
  const [loading, setLoading] = useState(false)

  const createUsersBatch = async (file) => {
    setLoading(true)
    try {
      const parseResult = await new Promise((resolve, reject) => {
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: (results) => {
            resolve(results)
          },
          error: (error) => {
            reject(error)
          },
        })
      })

      const newUsers = parseResult.data.map((row, index) => ({
        id: `user-${Date.now()}-${index}`,
        name: row.name || '',
        email: row.email || '',
      }))
      
      usersInMemory.push(...newUsers)
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  return { createUsersBatch, loading }
}
