// src/components/UserTable/UserTableRow.js

import React, { useCallback, useRef, useState } from 'react'
import { Checkbox, IconButton, Menu, MenuItem, TableCell, TableRow, Typography } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useDeleteUser } from '../../../hooks/userManagement/useDeleteUser'

const UserTableRow = React.memo(({ user, onEdit, refetchUsers, isSmallScreen, isSelected, onSelectUser }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { deleteUser } = useDeleteUser()

  // Estado para almacenar la acción pendiente
  const pendingActionRef = useRef(null)

  const handleMenuClick = useCallback((event) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  // Modificar los manejadores de clic para diferir la acción
  const handleEditClick = useCallback(() => {
    pendingActionRef.current = () => onEdit(user)
    handleMenuClose()
  }, [onEdit, user, handleMenuClose])

  const handleDeleteClick = useCallback(async () => {
    pendingActionRef.current = async () => {
      if (window.confirm('¿Estás seguro de eliminar este usuario?')) {
        try {
          await deleteUser(user.id)
          refetchUsers()
        } catch (error) {
          console.error(error)
          alert('Ocurrió un error al eliminar el usuario.')
        }
      }
    }
    handleMenuClose()
  }, [deleteUser, user.id, refetchUsers, handleMenuClose])

  // Ejecutar la acción pendiente una vez que el menú haya cerrado
  const handleMenuExited = () => {
    if (pendingActionRef.current) {
      pendingActionRef.current()
      pendingActionRef.current = null
    }
  }

  const handleCheckboxChange = () => {
    onSelectUser(user.id)
  }

  return (
    <TableRow key={user.id} style={{ backgroundColor: '#092838' }}>
      <TableCell padding="checkbox" style={{ borderBottom: 'none' }}>
        <Checkbox color="primary" checked={isSelected} onChange={handleCheckboxChange} />
      </TableCell>
      <TableCell style={{ color: 'white', padding: '8px', borderBottom: 'none' }}>
        <Typography variant="h6" fontWeight="normal" color="white !important">
          {user.name}
        </Typography>
      </TableCell>
      {!isSmallScreen && (
        <TableCell style={{ color: '#fff', padding: '8px', borderBottom: 'none' }}>{user.email}</TableCell>
      )}
      <TableCell style={{ padding: '8px', textAlign: 'center', borderBottom: 'none' }}>
        <IconButton onClick={handleMenuClick} style={{ color: '#fff' }}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          disableScrollLock
          TransitionProps={{
            onExited: handleMenuExited,
          }}
        >
          <MenuItem onClick={handleEditClick}>Editar</MenuItem>
          <MenuItem onClick={handleDeleteClick}>Eliminar</MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  )
})

export default UserTableRow
