import { useState } from 'react'
import PropTypes from 'prop-types'
import Fade from '@mui/material/Fade'

import { AlertCloseIcon, Box } from 'components/atoms'
import { AlertStyle } from './Alert.style'

function Alert({ color, dismissible, children, ...rest }) {
  const [alertStatus, setAlertStatus] = useState('mount')

  const handleAlertStatus = () => setAlertStatus('fadeOut')

  // The base template for the alert
  const alertTemplate = (mount = true) => (
    <Fade in={mount} timeout={300}>
      <AlertStyle ownerState={{ color }} {...rest}>
        <Box display="flex" alignItems="center" color="white">
          {children}
        </Box>
        {dismissible ? <AlertCloseIcon onClick={mount ? handleAlertStatus : null}>&times;</AlertCloseIcon> : null}
      </AlertStyle>
    </Fade>
  )

  switch (alertStatus) {
    case 'mount':
      return alertTemplate()
    case 'fadeOut':
      setTimeout(() => setAlertStatus('unmount'), 400)
      return alertTemplate(false)
    default:
      return null
  }
}

// Setting default values for the props of Alert
Alert.defaultProps = {
  color: 'info',
  dismissible: false,
}

// Typechecking props of the Alert
Alert.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'light', 'dark']),
  dismissible: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default Alert
