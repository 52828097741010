import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'

import { Box } from 'components/atoms'

function TablePageLayout({ children }) {
  return (
    <>
      <Box pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

TablePageLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TablePageLayout
