import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Card from '@mui/material/Card'
import { AuthContext } from '../../../context'
import { BasicLayoutLanding } from '../../templates'
import { Box, Button, Input, Typography } from '../../atoms'
import bgImage from '../../../assets/images/bg-sign-in-basic.jpeg'
import AuthService from '../../../services/auth-service'

function Login() {
  const navigate = useNavigate()
  const { login } = useContext(AuthContext)
  const [credentialsError, setCredentialsError] = useState(null)

  const [inputs, setInputs] = useState({
    email: '',
    password: '',
  })

  const [errors, setErrors] = useState({
    emailError: false,
    passwordError: false,
  })

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    })
  }

  const submitHandler = async (e) => {
    e.preventDefault()

    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    if (inputs.email.trim().length === 0 || !inputs.email.trim().match(mailFormat)) {
      setErrors({ ...errors, emailError: true })
      return
    }

    if (inputs.password.trim().length < 3) {
      setErrors({ ...errors, passwordError: true })
      return
    }

    try {
      const token = await AuthService.login(inputs.email, inputs.password)
      login(token)
      navigate('/')
    } catch (res) {
      if (res.hasOwnProperty('message')) {
        setCredentialsError(res.message)
      } else {
        // TODO: PAGINA DE ERROR
        setCredentialsError('An error occurred, please contact support')
      }
    }

    return () => {
      setInputs({
        email: '',
        password: '',
      })

      setErrors({
        emailError: false,
        passwordError: false,
      })
    }
  }

  return (
    <BasicLayoutLanding image={bgImage}>
      <Card>
        <Box
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <Typography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </Typography>
        </Box>
        <Box pt={4} pb={3} px={3}>
          <Box component="form" role="form" method="POST" onSubmit={submitHandler}>
            <Box mb={2}>
              <Input
                type="email"
                label="Email"
                fullWidth
                value={inputs.email}
                name="email"
                onChange={changeHandler}
                error={errors.emailError}
              />
            </Box>
            <Box mb={2}>
              <Input
                type="password"
                label="Password"
                fullWidth
                name="password"
                value={inputs.password}
                onChange={changeHandler}
                error={errors.passwordError}
              />
            </Box>
            <Box mt={4} mb={1}>
              <Button variant="gradient" color="info" fullWidth type="submit">
                sign in
              </Button>
            </Box>
            {credentialsError && (
              <Typography variant="caption" color="error" fontWeight="light">
                {credentialsError}
              </Typography>
            )}
          </Box>
        </Box>
      </Card>
    </BasicLayoutLanding>
  )
}

export default Login
