// LandingPage.js
import React, { useRef } from 'react'
import Header from './LandingHeader'
import Hero from './LandingHero'
import Features from './LandingFeatures'
import Benefits from './LandingBenefits'
import FAQ from './LandingFaq'
import Footer from './LandingFooter'
import Testimonial from './LandingTestimonial'
import { ThemeProvider } from '@mui/material/styles'
import theme from './theme'
import Box from '@mui/material/Box'

const LandingPage = () => {
  const faqRef = useRef(null)
  const scrollToFAQ = () => {
    faqRef.current.scrollIntoView({ behavior: 'smooth' })
  }
  return (
    <ThemeProvider theme={theme}>
      <div style={{ backgroundColor: '#041e2b', color: '#fff', fontFamily: 'Inter, sans-serif' }}>
        <Header scrollToFAQ={scrollToFAQ} />
        <main>
          <Box
            sx={{
              height: { xs: '100px', md: '150px' },
              background: 'linear-gradient(to bottom, #04121a, #041e2b)',
            }}
          />
          <Hero />
          <Features />
          <Box
            sx={{
              height: { xs: '100px', md: '150px' },
              background: 'linear-gradient(to bottom, #041e2b, #04121a)',
            }}
          />
          <Benefits />
          <Testimonial />
          <FAQ ref={faqRef} />
        </main>
        <Footer />
      </div>
    </ThemeProvider>
  )
}

export default LandingPage
