import React from 'react'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { LoadingSpinner } from '../../atoms'
import { useDrinkSalesData } from '../../../hooks/eventDetails/useDrinksSalesData'

const BarSales = ({ eventId }) => {
  const { drinksData, loading, error } = useDrinkSalesData({ eventId })

  console.log('drinksData', drinksData)

  if (loading) return <LoadingSpinner />

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          textAlign: 'center',
          backgroundColor: '#092838',
          borderRadius: '10px',
        }}
      >
        <Typography variant="h6" color="error">
          {error.message}
        </Typography>
        <Typography variant="body1">
          Por favor, intenta refrescar la página o contacta a soporte si el problema persiste.
        </Typography>
      </Box>
    )
  }

  const sumRevenue = () => {
    let sum = 0
    drinksData?.forEach((drink) => {
      sum += drink.revenue
    })
    return sum
  }

  return (
    <Box
      sx={{
        backgroundColor: '#092838',
        padding: {
          xs: '20px',
          sm: '25px',
          md: '30px',
        },
        borderRadius: '10px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        color: '#fff',
        height: '100%',
        maxHeight: '487px', // Establece la altura máxima
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      {/* Título */}
      <Box
        sx={{
          flexShrink: 0,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '16px',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: '#fff',
            fontWeight: 'bold',
            fontSize: {
              xs: '16px',
              sm: '18px',
            },
          }}
        >
          Ventas en Barra
        </Typography>
        <IconButton sx={{ color: '#fff' }}>
          <MoreHorizIcon /> {/* Icono de tres puntos horizontales */}
        </IconButton>
      </Box>
      <Box
        sx={{
          flexShrink: 0,
          width: '100%',
          height: '1px',
          backgroundColor: '#041E2B',
          marginBottom: '16px',
        }}
      />

      {/* Encabezados */}
      <Grid container spacing={1} sx={{ marginBottom: '8px', flexShrink: 0 }}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" sx={{ color: '#8796A5', fontSize: '14px' }}>
            Trago
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant="body2" sx={{ color: '#8796A5', fontSize: '14px', textAlign: 'center' }}>
            Cantidad
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant="body2" sx={{ color: '#8796A5', fontSize: '14px', textAlign: 'right' }}>
            Subtotal
          </Typography>
        </Grid>
      </Grid>

      {/* Línea divisora debajo de los encabezados */}
      <Box
        sx={{
          flexShrink: 0,
          width: '100%',
          height: '1px',
          backgroundColor: 'white !important',
          marginBottom: '16px',
        }}
      />

      {/* Contenedor scrollable para los items */}
      <Box
        sx={{
          flex: 1,
          overflowY: 'auto',
        }}
      >
        <Grid container spacing={1}>
          {drinksData?.map((item, index) => (
            <Grid key={index} item xs={12} container alignItems="center" sx={{ marginBottom: '8px' }}>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: '14px',
                    color: '#fff',
                    wordBreak: 'break-word',
                  }}
                >
                  {item.name}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography variant="body2" sx={{ fontSize: '14px', color: '#98A2B3', textAlign: 'center' }}>
                  {item.sold.toLocaleString().replaceAll(',', '.')}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography variant="body1" sx={{ fontSize: '14px', color: '#00E676', textAlign: 'right' }}>
                  {`$${item.revenue.toLocaleString().replaceAll(',', '.')}`}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Sección Total */}
      <Box sx={{ flexShrink: 0, marginTop: '16px' }}>
        <Box sx={{ textAlign: 'right' }}>
          {/* Título Total */}
          <Typography
            variant="body1"
            sx={{
              fontWeight: 'bold',
              fontSize: '16px',
            }}
          >
            Total
          </Typography>
          {/* Línea divisora */}
          <Box
            sx={{
              width: '100%',
              height: '1px',
              backgroundColor: '#ffffff',
              margin: '8px 0',
            }}
          />
          {/* Monto Total */}
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              fontSize: '18px',
              color: '#00E676',
            }}
          >
            {`$${sumRevenue().toLocaleString().replaceAll(',', '.')}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default BarSales
