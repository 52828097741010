import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'

import { ErrorBoundary } from 'components/templates'
import { StatisticInfo } from 'components/molecules'
import { Box } from 'components/atoms'
import { StatisticVariation } from '../../molecules'

function StatisticsCard({ title, icon, type, value, format, handleOnClick, redirectTo, variations }) {
  return (
    <ErrorBoundary>
      <Card onClick={() => handleOnClick && handleOnClick(redirectTo)} sx={{ cursor: 'pointer' }}>
        <Box pt={1} px={2}>
          <StatisticInfo title={title} value={value} icon={icon} format={format} />
        </Box>
        <Box mt={1} pb={2} px={2}>
          {variations.map((variation, index) => (
            <StatisticVariation key={index} variation={variation} type={type} />
          ))}
        </Box>
      </Card>
    </ErrorBoundary>
  )
}

StatisticsCard.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'light', 'dark']),
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentages: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark', 'white']),
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  icon: PropTypes.node.isRequired,
  handleOnClick: PropTypes.func,
  redirectTo: PropTypes.string.isRequired,
}

export default memo(StatisticsCard)
