// src/hooks/useCreateUser.js

import { usersInMemory } from '../memoryDataStore'

export const useCreateUser = () => {
  const createUser = async (userData) => {
    const newUser = {
      id: `user-${Date.now()}`,
      ...userData,
    }

    usersInMemory.push(newUser)
  }

  return { createUser }
}
