import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Menu from '@mui/material/Menu'
import Icon from '@mui/material/Icon'

import AuthService from 'services/auth-service'
import { AuthContext, setTransparentNavbar, useMangoController } from 'context'
import { NotificationItem } from 'components/molecules'
import { Box, Button } from 'components/atoms'
import { navbar } from './DashboardNavbar.style'
import LargeWindowSVG from '../../../assets/mango/logos/MangoTicket-Color-White.svg'
import SmallWindowSVG from '../../../assets/mango/logos/Mango.svg'
import { Link } from '@mui/material'

function DashboardNavbar({ absolute = false, light = false, isMini = false, title, showBackButton = false }) {
  const authContext = useContext(AuthContext)
  const [navbarType, setNavbarType] = useState()
  const [controller, dispatch] = useMangoController()
  const { transparentNavbar, fixedNavbar, darkMode, miniSidenav } = controller
  const [openMenu, setOpenMenu] = useState(false)
  const route = useLocation().pathname.split('/').slice(1)
  let navigate = useNavigate()

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType('sticky')
    } else {
      setNavbarType('static')
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar)
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
     */
    window.addEventListener('scroll', handleTransparentNavbar)

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar()

    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', handleTransparentNavbar)
  }, [dispatch, fixedNavbar])

  const handleCloseMenu = () => setOpenMenu(false)

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  )

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main
      }

      return colorValue
    },
  })

  const handleLogOut = async () => {
    const response = await AuthService.logout()
    authContext.logout()
  }

  const handleOnClick = (e) => {
    e.preventDefault()
    navigate('/events/new')
  }

  const handleBackButton = (e) => {
    e.preventDefault()
    navigate('/dashboard')
  }

  const renderSVG = () => {
    if (miniSidenav) {
      return <img src={SmallWindowSVG} alt="Small Window" style={{ width: '40px' }} />
    } else {
      return <img src={LargeWindowSVG} alt="Large Window" style={{ width: '200px' }} />
    }
  }

  const handleOnLogoClick = useCallback(() => {
    navigate('/')
  }, [navigate])

  return (
    <AppBar
      position={absolute ? 'absolute' : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      {/*<Toolbar sx={(theme) => navbarContainer(theme)}>*/}
      {/*  <Box color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>*/}
      {/*    <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />*/}
      {/*  </Box>*/}
      {/*</Toolbar>*/}
      <Box mb={{ xs: 1, md: 0 }} display="flex" flexDirection="row" justifyContent="space-between">
        <Box color="white" display="flex" alignItems="flex-start" mt={1} ml={1}>
          {/* with SideNav use <h2 color="white">{title}</h2>*/}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            onClick={handleOnLogoClick}
            sx={{ cursor: 'pointer' }}
          >
            {renderSVG()}
          </Box>
        </Box>
        <Box display="flex" alignItems={showBackButton ? 'center' : 'flex-end'} mr={3} mt={1}>
          {showBackButton && (
            <Link
              variant="contained"
              size="large"
              color="#fff"
              mr={3}
              textAlign="center"
              justifyContent="center"
              alignItems="center"
              onClick={handleBackButton}
              fontFamily="Inter, sans-serif"
              sx={{ cursor: 'pointer' }}
            >
              Volver al dashboard
            </Link>
          )}

          <Button
            variant="contained"
            size="large"
            color="warning"
            onClick={handleOnClick}
            sx={{ fontFamily: 'Inter, sans-serif' }}
          >
            Crear un Evento
          </Button>
        </Box>
      </Box>
    </AppBar>
  )
}

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
}

export default DashboardNavbar
