// src/components/atoms/StyledImage.jsx
import React from 'react'
import { Box } from '@mui/material'

const StyledImage = ({ src, alt, sx = {}, ...props }) => (
  <Box
    component="img"
    src={src}
    alt={alt}
    {...props}
    sx={{
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '18px',
      ...sx,
    }}
  />
)
export default StyledImage
