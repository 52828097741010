import React from 'react'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { Doughnut } from 'react-chartjs-2'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { useTicketSalesData } from '../../../hooks/eventDetails/useTicketSalesData'
import { LoadingSpinner } from '../../atoms'

const TicketSales = ({ eventId }) => {
  const { ticketData, loading, error } = useTicketSalesData({ eventId })

  if (loading) return <LoadingSpinner />

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          textAlign: 'center',
          backgroundColor: '#092838',
          borderRadius: '10px',
        }}
      >
        <Typography variant="h6" color="error">
          {error.message}
        </Typography>
        <Typography variant="body1">
          Por favor, intenta refrescar la página o contacta a soporte si el problema persiste.
        </Typography>
      </Box>
    )
  }

  // Verificar si todas las ventas son cero
  const allSoldZero = ticketData.every((ticket) => ticket.sold === 0)

  // Generar colores asignados para los tickets
  const generateColors = (num) => {
    const colors = []
    for (let i = 0; i < num; i++) {
      const hue = (i * 137.508) % 360 // Utiliza la secuencia de números áureos para distribuir los colores
      colors.push(`hsl(${hue}, 70%, 50%)`)
    }
    return colors
  }

  const assignedColors = generateColors(ticketData.length)

  // Configurar los colores del gráfico de dona
  const chartColors = allSoldZero
    ? ['#B0B0B0'] // Color gris cuando no hay ventas
    : assignedColors // Colores asignados cuando hay ventas

  // Configurar los datos para el gráfico de dona
  const data = allSoldZero
    ? {
        labels: ['Sin Ventas'],
        datasets: [
          {
            data: [1],
            backgroundColor: ['#B0B0B0'], // Color gris
            borderWidth: 0,
          },
        ],
      }
    : {
        labels: ticketData.map((ticket) => ticket.name),
        datasets: [
          {
            data: ticketData.map((ticket) => ticket.sold.toLocaleString().replaceAll(',', '.')),
            backgroundColor: chartColors,
            borderWidth: 0,
          },
        ],
      }

  // Opciones de configuración para el gráfico
  const options = {
    plugins: {
      legend: {
        display: false, // Ocultar la leyenda
      },
      tooltip: {
        enabled: !allSoldZero, // Habilitar tooltips solo si hay ventas
      },
    },
    cutout: '70%', // Tamaño del agujero central
    maintainAspectRatio: false, // Permitir ajustar el tamaño del gráfico
  }

  return (
    <Box
      sx={{
        backgroundColor: '#092838',
        padding: {
          xs: '20px',
          sm: '25px',
          md: '30px',
        },
        borderRadius: '10px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        maxHeight: '487px',
        width: '100%',
      }}
    >
      {/* Título con botón de más opciones */}
      <Box
        sx={{
          flexShrink: 0,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '16px',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: '#fff',
            fontWeight: 'bold',
            fontSize: {
              xs: '16px',
              sm: '18px',
            },
          }}
        >
          Ventas de Ticket
        </Typography>
        <IconButton sx={{ color: '#fff' }}>
          <MoreHorizIcon /> {/* Icono de tres puntos horizontales */}
        </IconButton>
      </Box>
      <Box
        sx={{
          flexShrink: 0,
          width: '100%',
          height: '1px',
          backgroundColor: '#041E2B',
          marginBottom: '8px',
        }}
      />

      {/* Contenedor principal ajustado */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          alignItems: 'flex-start',
          flexGrow: 1,
          overflow: 'hidden',
        }}
      >
        {/* Gráfico de Dona */}
        <Box
          sx={{
            flexShrink: 0,
            flexBasis: {
              xs: 'auto',
              md: '40%',
            },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: {
              xs: '16px',
              md: '0',
            },
            marginRight: {
              md: '16px',
            },
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: {
                xs: '150px',
                sm: '180px',
                md: '200px',
              },
              height: {
                xs: '150px',
                sm: '180px',
                md: '200px',
              },
            }}
          >
            {/* Ajustamos el tamaño del gráfico */}
            <Doughnut data={data} options={options} />
          </Box>
        </Box>

        {/* Detalles de los tickets con scroll */}
        <Box
          sx={{
            flexGrow: 1,
            maxHeight: '100%',
            overflowY: 'auto',
            marginLeft: {
              md: '16px',
            },
          }}
        >
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {ticketData.map((ticket, index) => (
              <Box
                key={index}
                sx={{
                  width: {
                    xs: '100%',
                    sm: '50%',
                  },
                  padding: '8px 0',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                  {/* Cuadrado de color */}
                  <Box
                    sx={{
                      minWidth: '12px',
                      width: '12px',
                      height: '12px',
                      backgroundColor: assignedColors[index], // Siempre usar colores asignados
                      marginRight: '8px',
                      flexShrink: 0,
                    }}
                  />
                  {/* Tooltip que muestra el nombre completo al pasar el mouse */}
                  <Tooltip title={ticket.name}>
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#98A2B3',
                        fontWeight: 'bold',
                        fontSize: '14px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        cursor: 'default',
                      }}
                    >
                      {ticket.name}
                    </Typography>
                  </Tooltip>
                </Box>
                <Typography variant="body2" sx={{ color: '#fff', marginBottom: '2px', fontSize: '14px' }}>
                  {`${ticket.sold.toLocaleString().replaceAll(',', '.')} / ${ticket.total.toLocaleString().replaceAll(',', '.')}`}
                </Typography>
                <Typography variant="body2" sx={{ color: '#00E676', fontSize: '14px' }}>
                  {`$${ticket.revenue.toLocaleString().replaceAll(',', '.')}`}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default TicketSales
