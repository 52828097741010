// Features.js
import React from 'react'
import { Box, Button, Container, Typography } from '@mui/material'

const FeatureCard = ({ tagline, title, description, imageUrl, altText }) => (
  <Box
    sx={{
      backgroundColor: '#fffff5',
      borderRadius: '10px',
      display: 'flex',
      flexDirection: { xs: 'column', md: 'row' },
      overflow: 'hidden',
      width: '100%',
    }}
  >
    <Box
      sx={{
        padding: { xs: 2, md: 3 },
        flex: 1,
      }}
    >
      <Typography
        sx={{
          color: '#f79009',
          fontSize: '16px',
          fontWeight: 600,
          marginBottom: '8px',
        }}
      >
        {tagline}
      </Typography>
      <Typography
        variant="h5"
        sx={{
          color: '#000',
          fontSize: '24px',
          marginBottom: '8px',
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          color: '#000',
          fontSize: '16px',
          marginBottom: '24px',
        }}
      >
        {description}
      </Typography>
      <Button
        sx={{
          backgroundColor: 'transparent',
          color: '#000',
          fontSize: '16px',
          textTransform: 'none',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          padding: 0,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        Descubre
        <Box
          component="img"
          src="https://cdn.builder.io/api/v1/image/assets/8d11d8ff049440d581f10d53cffd98cc/2c648c99086dabcc02665d7136f3813fa8d9ab4a20d15904140067b9f5dc1586?apiKey=8d11d8ff049440d581f10d53cffd98cc&"
          alt="Arrow icon"
          sx={{
            width: '24px',
            height: '24px',
          }}
        />
      </Button>
    </Box>
    <Box
      sx={{
        flex: 1,
        backgroundColor: '#092838',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: { xs: '0 0 10px 10px', md: '0 10px 10px 0' },
      }}
    >
      <Box
        component="img"
        src={imageUrl}
        alt={altText}
        sx={{
          maxWidth: '100%',
          height: 'auto',
        }}
      />
    </Box>
  </Box>
)

const Features = () => {
  const featuresData = [
    {
      tagline: 'Eficiencia',
      title: 'Todo en uno para tus eventos',
      description: 'Integra productos de barra fácilmente en tus eventos.',
      imageUrl:
        'https://cdn.builder.io/api/v1/image/assets/8d11d8ff049440d581f10d53cffd98cc/c0a60157b448d622094688c554ace33a8a6712a36e04b34b2035ad6ac3f6a238?apiKey=8d11d8ff049440d581f10d53cffd98cc&',
      altText: 'All-in-one event management',
    },
    {
      tagline: 'Eficiencia',
      title: 'Todo en uno para tus eventos',
      description: 'Integra productos de barra fácilmente en tus eventos.',
      imageUrl:
        'https://cdn.builder.io/api/v1/image/assets/8d11d8ff049440d581f10d53cffd98cc/91006fb438791c215018cb3633e8dea4b7cf300cb3017219a91fc0f6d124ee2d?apiKey=8d11d8ff049440d581f10d53cffd98cc&',
      altText: 'Integrated bar products',
    },
  ]

  return (
    <Box
      component="section"
      sx={{
        padding: { xs: '40px 20px 100px', md: '70px 64px 130px' },
        backgroundColor: '#041e2b',
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            textAlign: 'center',
            maxWidth: '768px',
            mx: 'auto',
            mb: { xs: 6, md: 10 },
          }}
        >
          <Typography
            sx={{
              color: '#f79009',
              fontSize: '16px',
              fontWeight: 600,
              marginBottom: '16px',
            }}
          >
            Simplicidad
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '40px', md: '48px' },
              lineHeight: 1.2,
              marginBottom: '24px',
            }}
          >
            Herramientas para eventos,{' '}
            <Box component="span" sx={{ color: '#fff' }}>
              sin complicaciones.
            </Box>
          </Typography>
          <Typography
            sx={{
              color: '#aaa',
              fontSize: '18px',
            }}
          >
            Gestión de eventos sin complicaciones para todos.
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: '32px',
            flexWrap: 'wrap',
          }}
        >
          {featuresData.map((feature, index) => (
            <Box key={index} sx={{ width: { xs: '100%', md: 'calc(50% - 16px)' } }}>
              <FeatureCard {...feature} />
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  )
}

export default Features
