// src/components/CSVUpload/CSVUpload.js

import React, { useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useCreateUsersBatch } from '../../../hooks/userManagement/useCreateUsersBatch'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

const CSVUpload = ({ onUploadSuccess }) => {
  const [file, setFile] = useState(null)
  const { createUsersBatch, loading } = useCreateUsersBatch()

  const handleFileChange = (e) => {
    setFile(e.target.files[0])
  }

  const handleUpload = async () => {
    if (!file) {
      alert('Por favor, selecciona un archivo CSV.')
      return
    }

    try {
      await createUsersBatch(file)
      alert('Usuarios cargados exitosamente.')
      setFile(null)
      onUploadSuccess()
    } catch (error) {
      console.error(error)
      alert('Ocurrió un error al cargar el archivo CSV.')
    }
  }

  return (
    <Box sx={{ marginBottom: 2 }}>
      <Typography variant="h6" sx={{ color: '#fff', marginBottom: 1, fontWeight: 'bold' }}>
        Subir CSV de Usuarios
      </Typography>
      <input accept=".csv" id="csv-upload" type="file" onChange={handleFileChange} style={{ display: 'none' }} />
      <label htmlFor="csv-upload">
        <Button
          variant="contained"
          component="span"
          sx={{
            backgroundColor: '#F79009',
            color: 'white',
            borderRadius: '8px',
            fontWeight: 'bold',
            padding: '6px 12px',
            marginRight: 2,
          }}
          startIcon={<CloudUploadIcon />}
        >
          Seleccionar Archivo
        </Button>
      </label>
      {file && (
        <Typography variant="body1" sx={{ color: '#fff', marginTop: 1 }}>
          Archivo seleccionado: {file.name}
        </Typography>
      )}
      <Button
        variant="contained"
        onClick={handleUpload}
        sx={{
          backgroundColor: '#F79009',
          color: 'white',
          borderRadius: '8px',
          fontWeight: 'bold',
          padding: '6px 12px',
          marginTop: file ? 2 : 0,
        }}
        disabled={loading}
      >
        {loading ? 'Cargando...' : 'Subir'}
      </Button>
    </Box>
  )
}

export default CSVUpload
