// src/pages/EventDetailPage.jsx
import React from 'react'
import EventDetailTemplate from '../../templates/EventDetailTemplate/EventDetailTemplate'
import { ThemeProvider } from '@mui/material/styles'
import theme from './theme'
import { DashboardLayout, DashboardNavbar } from '../../templates'
import Box from '@mui/material/Box'

const EventDetailPage = () => (
  <DashboardLayout>
    <Box py={3}>
      <DashboardNavbar absolute showBackButton />
    </Box>
    <Box mt={2} mb={4}>
      <hr
        style={{
          height: '1px',
          border: 'none',
          backgroundColor: '#667085',
        }}
      />
    </Box>
    <ThemeProvider theme={theme}>
      <EventDetailTemplate />
    </ThemeProvider>
  </DashboardLayout>
)

export default EventDetailPage
