import React from 'react'
import DragAndDropBox from '../../atoms/DragAndDropBox/DragAndDropBox'

const FileUploader = ({ handleImage, data }) => {
  const handleImageData = ({ croppedImage, name }) => {
    handleImage(croppedImage, name)
  }

  return (
    <DragAndDropBox
      setImageData={handleImageData}
      data={data}
      accept="image/*"
      message={data?.rawImageName || 'Sube o arrastra la imagen aquí'}
    />
  )
}

export default FileUploader
