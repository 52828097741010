import React from 'react'
import { Box } from '@mui/material'
import EventPreview from '../EventPreview/EventPreview'
import TicketPreview from '../TicketPreview/TicketPreview'
import BarPreview from '../BarPreview/BarPreview'

const CombinedPreview = ({ data, tickets, bar }) => {
  return (
    <Box>
      <EventPreview data={data} />
      <TicketPreview tickets={tickets} setTickets={() => {}} isCombined={true} />
      <BarPreview bar={bar} setBar={() => {}} />
    </Box>
  )
}

export default CombinedPreview
