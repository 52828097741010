import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from 'App'
import { AuthContextProvider, MangoControllerProvider } from 'context'
import { ApolloProvider } from '@apollo/client'
import client from './services/apollo-service'

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

const MemoizedAuthContextProvider = React.memo(AuthContextProvider)
const MemoizedMangoControllerProvider = React.memo(MangoControllerProvider)

root.render(
  <BrowserRouter>
    <MemoizedAuthContextProvider>
      <MemoizedMangoControllerProvider>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </MemoizedMangoControllerProvider>
    </MemoizedAuthContextProvider>
  </BrowserRouter>
)
