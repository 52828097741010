import PropTypes from 'prop-types'

import { Typography } from 'components/atoms'
import Icon from '@mui/material/Icon'

function getIcon(variation) {
  if (variation.type === 'INCREASE') return 'arrow_drop_up'
  if (variation.type === 'DECREASE') return 'arrow_drop_down'
  return 'remove'
}

function getColor(variation) {
  if (variation.type === 'INCREASE') return 'success'
  if (variation.type === 'DECREASE') return 'error'
  return 'text'
}

function getOperation(variation) {
  if (variation.type === 'INCREASE') return 'más que'
  if (variation.type === 'DECREASE') return 'menos que'
  if (variation.type === 'NONE') return 'metrica no disponible'
  return ''
}

function getInterval(variation) {
  if (variation.type === 'NONE') return ''
  if (variation.interval === 'DAY') return 'el día de ayer'
  if (variation.interval === 'WEEK') return 'la semana pasada'
  if (variation.interval === 'MONTH') return 'el mes pasado'
  if (variation.interval === 'YEAR') return 'el año pasado'
  return ''
}

function getAmount(variation) {
  if (variation.type === 'NONE') return ''
  if (variation.format === 'PERCENTAGE') return `${variation.amount}%`
  return variation.amount
}

function StatisticVariation({ variation, type }) {
  return (
    <Typography component="p" variant="button" display="flex" color={getColor(variation)}>
      <Icon fontSize="small" alignItems="flex-end">
        {getIcon(variation)}
      </Icon>
      <Typography component="span" variant="button" fontWeight="bold" color={getColor(variation)}>
        {getAmount(variation, type)}
      </Typography>
      &nbsp;{getOperation(variation)} {getInterval(variation)}
    </Typography>
  )
}

StatisticVariation.propTypes = {
  variation: PropTypes.shape({
    type: PropTypes.string.isRequired,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
}

export default StatisticVariation
