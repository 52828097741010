export const parseIsoDate = (date) => {
  if (!date) return null

  try {
    const [datePart] = date.split(' ')
    const [year, month, day] = datePart.split('-').map(Number)

    const monthNames = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ]

    return `${String(day).padStart(2, '0')} ${monthNames[month - 1]} ${year}`
  } catch (error) {
    return null
  }
}

export const parseIsoHour = (hour) => {
  if (!hour) return null

  try {
    const [hours, minutes, seconds] = hour.split(':').map(Number)

    if (
      isNaN(hours) ||
      isNaN(minutes) ||
      isNaN(seconds) ||
      hours < 0 ||
      hours > 23 ||
      minutes < 0 ||
      minutes > 59 ||
      seconds < 0 ||
      seconds > 59
    ) {
      throw new Error('Invalid hour')
    }

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
  } catch (error) {
    return null
  }
}
