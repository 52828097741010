import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import graphqlService from '../../services/graphql.service'

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const location = useLocation()

  const checkAuthentication = async () => {
    try {
      const response = await graphqlService.verifyAuth()

      if (response.isAuthenticated) {
        setIsAuthenticated(true)
      } else {
        setIsAuthenticated(false)
      }
    } catch (error) {
      setIsAuthenticated(false)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    checkAuthentication()
  }, [])

  useEffect(() => {
    if (!loading) {
      if (isAuthenticated) {
        navigate(location.pathname)
      } else {
        navigate('/auth/login')
      }
    }
  }, [isAuthenticated, loading, navigate, location.pathname])

  return { isAuthenticated, loading, setIsAuthenticated }
}
