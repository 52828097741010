import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { useStatisticsData } from '../../../hooks/dashboard/useStatisticsData'
import { StatisticsCard } from '../../organisms'
import { Box, LoadingSpinner, Typography } from '../../atoms'

function Statistics() {
  const { statisticsData, loading, error } = useStatisticsData()
  const navigate = useNavigate()
  const location = useLocation()
  const [subscriptionKey, setSubscriptionKey] = useState(0)

  useEffect(() => {
    setSubscriptionKey((prevKey) => prevKey + 1)
  }, [location.pathname])

  const handleOnClick = useCallback(
    (url) => {
      navigate(url)
    },
    [navigate]
  )

  if (loading) return <LoadingSpinner />
  if (error) {
    return (
      <Box>
        <Typography variant="h6" color="error">
          {error.message}
        </Typography>
        <Typography variant="body1">
          Por favor, intenta refrescar la página o contacta a soporte si el problema persiste.
        </Typography>
      </Box>
    )
  }

  return (
    <Box key={subscriptionKey}>
      <Box mb={4}>
        <Typography variant="h4" color="white" fontWeight="bold">
          Analíticas del último mes
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Aquí puedes ver las estadísticas más recientes de tus eventos.
        </Typography>
      </Box>
      <Grid container spacing={2} justifyContent="space-between">
        {statisticsData.map((stat, index) => (
          <Grid item xs={12} md={4} lg={4} key={index}>
            <Box mb={1.5}>
              <StatisticsCard
                icon={stat.icon}
                title={stat.name}
                value={stat.value}
                handleOnClick={handleOnClick}
                redirectTo={stat.redirectTo}
                variations={stat.variations ?? []}
                type={stat.type}
                format={stat.format}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default React.memo(Statistics)
