import { useEffect, useState } from 'react'
import { gql, useQuery, useSubscription } from '@apollo/client'

const GET_TOTAL_SALES = gql`
  query GetTotalSales($input: TotalSalesInput!) {
    totalSales(input: $input) {
      net_sales
      ticket_sales
      bar_sales
    }
  }
`

const TOTAL_SALES_SUBSCRIPTION = gql`
  subscription OnTotalSalesUpdated($input: TotalSalesInput!) {
    totalSalesUpdated(input: $input) {
      net_sales
      ticket_sales
      bar_sales
    }
  }
`

export const useTotalSalesData = ({ eventId }) => {
  const [salesData, setSalesData] = useState({
    net_sales: 0,
    ticket_sales: 0,
    bar_sales: 0,
  })
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
  } = useQuery(GET_TOTAL_SALES, { variables: { input: { event_id: eventId } } })
  const { data: subscriptionData, error: subscriptionError } = useSubscription(TOTAL_SALES_SUBSCRIPTION, {
    variables: { input: { event_id: eventId } },
  })

  useEffect(() => {
    if (queryData) {
      setSalesData(queryData.totalSales)
      setLoading(false)
    }

    if (queryError) {
      setError(queryError)
      setLoading(false)
    }
  }, [queryData, queryError])

  useEffect(() => {
    if (subscriptionData) {
      setSalesData(subscriptionData.totalSalesUpdated)
    }

    if (subscriptionError) {
      setError(subscriptionError)
    }
  }, [subscriptionData, subscriptionError])

  return {
    netSales: salesData.net_sales,
    ticketSales: salesData.ticket_sales,
    barSales: salesData.bar_sales,
    loading: loading || queryLoading,
    error,
  }
}
