import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { List, ListItem, ListItemText, Typography } from '@mui/material'

import { PageLayout } from 'components/templates'
import { AuthFooter } from 'components/organisms'
import { Box } from 'components/atoms'

function BasicLayout({ image, children }) {
  const { pathname } = useLocation()
  return (
    <PageLayout>
      <Box sx={{ height: 'auto', minHeight: '100vh' }} display="flex" flexDirection="column" minHeight="100vh">
        <Box
          position="absolute"
          width="100%"
          minHeight="100vh"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              image &&
              `${linearGradient(rgba(gradients.dark.main, 0.6), rgba(gradients.dark.state, 0.6))}, url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <Box
            position="relative"
            height="100%"
            display="flex"
            flexDirection="column"
            width="100%"
            justifyContent="center"
            paddingTop="12em"
          >
            <Box paddingBottom="3rem" sx={{ textAlign: 'center' }}>
              {pathname === '/auth/Login' && (
                <Box display="flex" width="100%" justifyContent="center" sx={{ zIndex: '99' }}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    padding="1.5rem"
                    width="80%"
                  >
                    <Typography variant="h3" style={{ color: 'white' }}>
                      Log in to Material Dashboard NodeJS Live Preview
                    </Typography>
                    <Typography variant="body2" style={{ color: 'white' }} margin="0.5rem 0">
                      Log in to see how you can go from frontend to fullstack in an instant with an API-based NodeJS
                      backend
                    </Typography>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      marginBottom="0.5rem"
                    >
                      <Typography variant="body2" fontWeight="700" style={{ color: 'white' }}>
                        You can log in with
                      </Typography>
                      <List dense={true}>
                        <ListItem>
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography variant="body2" fontWeight="400" style={{ color: 'white' }}>
                                Username{' '}
                                <Typography variant="span" fontWeight="700">
                                  admin@jsonapi.com
                                </Typography>{' '}
                                with password{' '}
                                <Typography variant="span" fontWeight="700">
                                  secret
                                </Typography>
                              </Typography>
                            }
                          />
                        </ListItem>
                      </List>
                    </Box>
                  </Box>
                </Box>
              )}
              <Box px={1} width="100%" mx="auto" paddingTop="1rem">
                <Grid container spacing={1} justifyContent="center" alignItems="center">
                  <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
                    {children}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
          <AuthFooter light />
        </Box>
      </Box>
    </PageLayout>
  )
}

BasicLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default BasicLayout
