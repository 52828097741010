import React from 'react'
import { Grid, Typography } from '@mui/material'
import BarItem from '../BarItem/BarItem'

const BarPreview = ({ bar, setBar }) => {
  const handleIncrement = (index) => {
    const newBar = [...bar]
    if (!newBar[index].data.count) {
      newBar[index].data.count = 0
    }
    newBar[index].data.count += 1
    setBar(newBar)
  }

  const handleDecrement = (index) => {
    const newBar = [...bar]
    if (newBar[index].data.count > 0) {
      newBar[index].data.count -= 1
      setBar(newBar)
    }
  }

  return (
    <Grid
      container
      width="96%"
      margin="5px"
      pt={2}
      pb={3}
      sx={{
        color: 'white',
        backgroundColor: '#04121A',
        borderRadius: '10px',
        paddingLeft: '5px',
        paddingRight: '5px',
      }}
    >
      <Grid item xs={12} pl={2} mt={1}>
        <Typography variant="h6">Bar</Typography>
      </Grid>
      {bar.map((ticket, index) => (
        <BarItem
          key={ticket.id}
          ticketNumber={ticket.id}
          name={ticket.data.name}
          price={ticket.data.price}
          image={ticket.data.image} // Pasamos la imagen al BarItem
          count={ticket.data.count || 0}
          onIncrement={() => handleIncrement(index)}
          onDecrement={() => handleDecrement(index)}
        />
      ))}
    </Grid>
  )
}

export default BarPreview
