import { useEffect, useState } from 'react'
import { fetchEventDetails } from 'services/events-service'

const useFetchEventDataById = (eventId) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    const getEventDetails = async () => {
      setLoading(true)
      try {
        const data = await fetchEventDetails(eventId)
        setData(data?.getEventById?.event)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }
    }

    if (refresh) {
      getEventDetails()
      setRefresh(false)
    }
  }, [refresh])

  return { data, loading, error, refreshData: () => setRefresh(true) }
}

export default useFetchEventDataById
