// src/components/pages/NotFoundPage/NotFoundPage.jsx
import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { setLayout, useMangoController } from '../../../context'

const NotFound = () => {
  const [controller, dispatch] = useMangoController()

  useEffect(() => {
    setLayout(dispatch, 'plain')
  }, [dispatch])

  return (
    <Box
      sx={{
        display: 'flex',
        textAlign: 'center',
        marginTop: '50px',
        backgroundColor: 'white !important',
        width: '100%',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h4" gutterBottom>
        404 - Página No Encontrada
      </Typography>
      <Typography variant="body1">La página que buscas no existe.</Typography>
    </Box>
  )
}

export default NotFound
