import React from 'react'
import { Box, Chip, Typography } from '@mui/material'
import { statusColors, statusText } from '../../../utils/constants'
import { parseIsoDate, parseIsoHour } from '../../../utils/date'
import { truncateString } from '../../../utils/strings'

const EventData = ({ data }) => {
  return (
    // Container Box for Event Data
    <Box
      sx={{
        backgroundColor: '#092838',
        padding: {
          xs: '20px',
          sm: '25px',
          md: '30px',
        },
        borderRadius: '10px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
    >
      {/* Title for Event Details */}
      <Typography
        variant="h6"
        sx={{
          color: '#fff',
          marginBottom: '16px',
          fontWeight: 'bold',
          fontSize: {
            xs: '16px',
            sm: '18px',
          },
        }}
      >
        Detalles del Evento
      </Typography>

      {/* Divider Line */}
      <Box
        sx={{
          width: '100%',
          height: '1px',
          backgroundColor: '#041E2B',
          marginBottom: '16px',
        }}
      />

      {/* Content Box for Event Fields */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', flexGrow: 1 }}>
        {/* Event Status */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: '16px',
              width: {
                xs: '100%',
                sm: '120px',
              },
              marginBottom: {
                xs: '8px',
                sm: '0',
              },
            }}
          >
            Estado:
          </Typography>
          <Box
            sx={{
              color: '#000',
              padding: '2px 12px',
              borderRadius: '12px',
              fontWeight: 'bold',
              fontSize: '14px',
            }}
          >
            <Chip
              label={statusText[data?.status]}
              color={statusColors[data?.status]}
              sx={{ width: '88px', height: '24px', borderRadius: '20px' }}
            />
          </Box>
        </Box>

        {/* Event Start Date */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: '16px',
              width: {
                xs: '100%',
                sm: '120px',
              },
              marginBottom: {
                xs: '8px',
                sm: '0',
              },
            }}
          >
            Fecha Inicio:
          </Typography>
          <Typography variant="body1" sx={{ color: '#98A2B3', fontSize: '16px' }}>
            {parseIsoDate(data?.start_at)}, {parseIsoHour(data?.start_hour)}hrs
          </Typography>
        </Box>

        {/* Event End Date */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: '16px',
              width: {
                xs: '100%',
                sm: '120px',
              },
              marginBottom: {
                xs: '8px',
                sm: '0',
              },
            }}
          >
            Fecha Termino:
          </Typography>
          <Typography variant="body1" sx={{ color: '#98A2B3', fontSize: '16px' }}>
            {parseIsoDate(data?.end_at)}, {parseIsoHour(data?.end_hour)}hrs
          </Typography>
        </Box>

        {/* Event Address */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: '16px',
              width: {
                xs: '100%',
                sm: '120px',
              },
              marginBottom: {
                xs: '8px',
                sm: '0',
              },
            }}
          >
            Dirección:
          </Typography>
          <Typography variant="body1" sx={{ color: '#98A2B3', fontSize: '16px' }}>
            {truncateString(data?.address, 34)}
          </Typography>
        </Box>

        {/* Event Venue */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: '16px',
              width: {
                xs: '100%',
                sm: '120px',
              },
              marginBottom: {
                xs: '8px',
                sm: '0',
              },
            }}
          >
            Venue:
          </Typography>
          <Typography variant="body1" sx={{ color: '#98A2B3', fontSize: '16px' }}>
            {truncateString(data?.place, 34)}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default EventData
