// src/components/molecules/TicketItem.jsx
import React from 'react'
import { Box, Typography } from '@mui/material'

const TicketItem = ({
  name,
  description,
  price,
  quantity,
  onDecrease,
  onIncrease,
  selected,
  onMouseEnter,
  sx = {},
}) => (
  <Box
    onMouseEnter={onMouseEnter}
    sx={{
      borderRadius: '18px',
      backgroundColor: selected ? '#041e2b' : '#07181e',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '31px 15px',
      minHeight: '100px',
      width: '100%',
      flexDirection: { xs: 'row', sm: 'row' },
      margin: 0,
      ...sx,
    }}
  >
    <Box sx={{ flex: 1 }}>
      <Typography variant="h3" sx={{ color: '#fff' }}>
        {name}
      </Typography>
      <Typography variant="caption" sx={{ color: '#98a2b3', marginTop: '5px' }}>
        {description}
      </Typography>
    </Box>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        justifyContent: 'flex-end',
        flex: 1,
      }}
    >
      <Typography variant="body2" sx={{ color: '#f79009', fontWeight: 600, marginTop: '5px' }}>
        {price} c/u
      </Typography>
    </Box>
  </Box>
)

export default TicketItem
