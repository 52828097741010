// src/components/organisms/EventInfoSection.jsx
import React from 'react'
import { Box, Typography } from '@mui/material'
import StyledImage from '../../atoms/StyledImage/StyledImage'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as EditIcon } from '../../../assets/mango/icons/edit.svg'

const EventInfoSection = () => (
  <Box
    sx={{
      borderRadius: '18px',
      backgroundColor: '#07181e',
      padding: '35px',
      width: { xs: '100%', md: '398px' },
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      color: '#fff',
      overflow: 'auto',
      maxHeight: '583px',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant="h1" sx={{ color: '#fff' }}>
        Summer Park Electronik #2
      </Typography>
      <IconButton
        aria-label="Editar Evento"
        sx={{ color: '#fff' }}
        onClick={() => {
          // Lógica para editar
        }}
      >
        <EditIcon />
      </IconButton>
    </Box>

    {/* Date and Time */}
    <Box sx={{ marginTop: '24px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
        <StyledImage
          src="https://cdn.builder.io/api/v1/image/assets/8d11d8ff049440d581f10d53cffd98cc/34641026f2cab03033752052f88bf40e0375a1b6ea6bd094731ff2e85e943255"
          alt="Calendar"
          sx={{ width: '30px' }}
        />
        <Typography variant="body1" sx={{ color: '#98a2b3' }}>
          Sábado, 14 de Diciembre
          <br />
          19:00 - 05:00hrs
        </Typography>
      </Box>
    </Box>

    {/* Location */}
    <Box sx={{ marginTop: '18px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
        <StyledImage
          src="https://cdn.builder.io/api/v1/image/assets/8d11d8ff049440d581f10d53cffd98cc/1e308a310a6ba25993acc4f09b741be893dcdd3de4313f0d5dd38b0bc34c7012"
          alt="Location"
          sx={{ width: '30px' }}
        />
        <Typography variant="body1" sx={{ color: '#98a2b3' }}>
          Parque Padre Hurtado
          <br />
          Av. Francisco Bilbao 100, Las Condes
        </Typography>
      </Box>
    </Box>

    {/* Event Description */}
    <Box sx={{ marginTop: '24px' }}>
      <Typography variant="h2" sx={{ color: '#fff' }}>
        Sobre el evento
      </Typography>
      <Box
        sx={{
          width: '100%',
          height: '1px',
          backgroundColor: '#344054',
          marginTop: '12px',
        }}
      />
      <Typography variant="body1" sx={{ color: '#eaecf0', marginTop: '12px', lineHeight: '27px' }}>
        ¡Confirmadísima nuestra tercera fecha de la temporada! Por primera vez recibimos en Piknic Électronik Santiago a
        dos leyendas: ¡The Blessed Madonna & Spencer Parker! 🗣️🌈🧡🌞🌼 Mostrar más
      </Typography>
    </Box>

    {/* Organizer Info */}
    <Box sx={{ marginTop: '24px' }}>
      <Typography variant="h2" sx={{ color: '#fff' }}>
        Organizado por
      </Typography>
      <Box
        sx={{
          width: '100%',
          height: '1px',
          backgroundColor: '#344054',
          marginTop: '14px',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          marginTop: '14px',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#d88787',
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 500,
            fontSize: '20px',
            color: '#fff',
          }}
        >
          B
        </Box>
        <Typography variant="h3" sx={{ color: '#fff' }}>
          Ballonx
        </Typography>
      </Box>
      <Typography variant="body2" sx={{ color: '#98a2b3', marginTop: '14px' }}>
        Contactar al anfitrión
      </Typography>
    </Box>
  </Box>
)

export default EventInfoSection
