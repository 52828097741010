// Header.js
import React from 'react'
import { AppBar, Box, Button, Toolbar } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'

const Header = ({ scrollToFAQ }) => {
  const isMobile = useMediaQuery('(max-width:991px)')

  return (
    <AppBar position="static" sx={{ backgroundColor: '#04121a', py: { xs: 2, md: 3 } }}>
      <Toolbar
        sx={{
          maxWidth: '1312px',
          width: '100%',
          mx: 'auto',
          px: { xs: 2, sm: 4 },
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'white',
          borderRadius: '10px',
        }}
      >
        <Box
          component="img"
          src="https://cdn.builder.io/api/v1/image/assets/8d11d8ff049440d581f10d53cffd98cc/1826af55129e5e2ff0a2be9ea7aad79480c6c4b749efd215614fff23aa9aef07?apiKey=8d11d8ff049440d581f10d53cffd98cc&"
          alt="MangoTicket Logo"
          sx={{
            width: { xs: '100px', md: '148px' },
            height: 'auto',
            flexShrink: 0,
          }}
        />
        <Box sx={{ flexGrow: 1 }} />
        {!isMobile && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: { xs: 1, md: 4 },
              mr: 5,
            }}
          >
            <Button href="/dashboard" sx={{ color: '#000', fontSize: '16px', textTransform: 'none' }}>
              Productores
            </Button>
            <Button onClick={scrollToFAQ} sx={{ color: '#000', fontSize: '16px', textTransform: 'none' }}>
              Preguntas Frecuentes
            </Button>
          </Box>
        )}
        <Button
          variant="contained"
          href="/events/new"
          sx={{
            backgroundColor: '#f79009',
            color: '#fff',
            borderRadius: '10px',
            padding: '8px 20px',
            fontSize: '16px',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#e67e00',
            },
          }}
        >
          Crea tu Evento
        </Button>
      </Toolbar>
    </AppBar>
  )
}

export default Header
