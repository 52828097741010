// src/components/molecules/FormField.js
import React from 'react'
import TextFieldAtom from '../../atoms/ModalTextField/ModalTextField'

const FormField = ({ label, name, value, onChange, type = 'text', error, helperText }) => (
  <TextFieldAtom
    label={label}
    name={name}
    value={value}
    onChange={onChange}
    type={type}
    error={error}
    helperText={helperText}
  />
)

export default FormField
