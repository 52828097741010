import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import { setLayout, useMangoController } from 'context'
import { Box } from 'components/atoms'

function PageLayout({ background = 'default', children }) {
  const [, dispatch] = useMangoController()
  const { pathname } = useLocation()

  useEffect(() => {
    setLayout(dispatch, 'page')
  }, [pathname])

  return (
    <Box width="100vw" height="100%" minHeight="100vh" bgColor={background} sx={{ overflowX: 'hidden' }}>
      {children}
    </Box>
  )
}

PageLayout.propTypes = {
  background: PropTypes.oneOf(['white', 'light', 'default']),
  children: PropTypes.node.isRequired,
}

export default PageLayout
