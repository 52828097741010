import React, { memo } from 'react'

import { DashboardLayout, DashboardNavbar } from '../../templates'
import { Events, Statistics } from '../../organisms'
import { Box } from '../../atoms'

const MemoizedStatistics = memo(Statistics)

const DashboardPage = () => {
  return (
    <DashboardLayout>
      <Box py={3}>
        <DashboardNavbar absolute title="Mango Producciones" />
      </Box>
      <Box mt={2} mb={4}>
        <hr
          style={{
            height: '1px',
            border: 'none',
            backgroundColor: '#667085',
          }}
        />
      </Box>
      <Box>
        <MemoizedStatistics />
      </Box>
      <Box mt={4} mb={6}>
        <hr
          style={{
            height: '1px',
            border: 'none',
            backgroundColor: '#667085',
          }}
        />
      </Box>
      <Events title="Próximos Eventos" height="360px" />
    </DashboardLayout>
  )
}

export default memo(DashboardPage)
