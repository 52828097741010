import React from 'react'
import { Box, Divider, IconButton, Typography } from '@mui/material'
import { ReactComponent as CustomIcon } from '../../../assets/mango/icons/edit.svg'
import { format, parse } from 'date-fns'
import { es } from 'date-fns/locale'
import { truncateString } from '../../../utils/strings'

const EventDetails = ({ data, tickets, bar, setStep }) => {
  const renderDate = () => {
    const dateStr = data.startDate
    const parsedDate = parse(dateStr, 'dd-MM-yyyy', new Date())
    const formattedDate = format(parsedDate, "EEEE dd 'de' MMMM", { locale: es })
    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1)

    const capitalizedDate = formattedDate
      .split(' ')
      .map((word) => capitalize(word))
      .join(' ')
    return (
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
        <Typography>Fecha</Typography>
        <Typography>{capitalizedDate}</Typography>
      </Box>
    )
  }
  const renderHours = () => {
    return (
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
        <Typography>Horario</Typography>
        <Typography>{`${data.startHour}hrs a ${data.endHour}hrs`}</Typography>
      </Box>
    )
  }

  return (
    <Box sx={{ p: 2 }}>
      {/* Datos del Evento */}
      <Box mb={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Datos de tu Evento</Typography>
          <IconButton color="primary" onClick={() => setStep(0)}>
            <CustomIcon />
          </IconButton>
        </Box>
        <Box mt={2}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography>Nombre Evento</Typography>
            <Typography>{data.name}</Typography>
          </Box>
          <Divider sx={{ height: '1px', backgroundColor: '#E9E9E9' }} />
          <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
            <Typography>Descripción</Typography>
            <Typography>{truncateString(data.description)}</Typography>
          </Box>
          <Divider sx={{ height: '1px', backgroundColor: '#E9E9E9' }} />
          {renderDate()}
          <Divider sx={{ height: '1px', backgroundColor: '#E9E9E9' }} />
          {renderHours()}
        </Box>
      </Box>

      {/* Tickets */}
      <Box mb={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Tickets</Typography>
          <IconButton color="primary" onClick={() => setStep(1)}>
            <CustomIcon />
          </IconButton>
        </Box>
        <Box mt={2}>
          {tickets.map((ticket, index) => (
            <Box key={index} mb={2}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography>{ticket.data.name}</Typography>
                <Typography>{`$ ${parseInt(ticket.data.price).toLocaleString('es-CL')}`}</Typography>
              </Box>
              <Divider sx={{ height: '1px', backgroundColor: '#E9E9E9' }} />
            </Box>
          ))}
        </Box>
      </Box>

      {/* Bar */}
      {bar.length > 0 && (
        <Box mb={4}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Barra</Typography>
            <IconButton color="primary" onClick={() => setStep(2)}>
              <CustomIcon />
            </IconButton>
          </Box>
          <Box mt={2}>
            {bar.map((item, index) => (
              <Box key={index} mb={2}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography>{item.data.name}</Typography>
                  <Typography>{`$ ${parseInt(item.data.price).toLocaleString('es-CL')}`}</Typography>
                </Box>
                <Divider sx={{ height: '1px', backgroundColor: '#E9E9E9' }} />
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default EventDetails
