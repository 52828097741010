import { Box, Grid, Typography } from '@mui/material'
import placeholder from '../../../assets/mango/images/placeholder.jpg'
import React from 'react'
import Icon from '@mui/material/Icon'

const EventPreview = ({ data }) => {
  return (
    <>
      <Grid container width="100%" sx={{ backgroundColor: '#092838' }}>
        <Grid item xs={12}>
          <Box
            sx={{
              height: '100%',
              width: '100%',
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            <img
              src={data.image || placeholder}
              style={{
                width: '100%',
                objectFit: 'contain',
                display: 'block',
              }}
              alt="magik garden"
            />
            {/* TODO: edit alt */}
          </Box>
        </Grid>
      </Grid>
      <Grid container width="100%" height="10%" alignContent="center" sx={{ paddingLeft: '1rem', color: 'white' }}>
        <Grid item xs={12} mt={1}>
          <Box>
            <Typography variant="h6">Acerca del evento</Typography>
            <Typography variant="subtitle1">{data.name || data.defaultName}</Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container width="100%" height="10%">
        <Grid item xs={12}>
          <Box sx={{ height: '100%', width: '100%', paddingLeft: '1rem', color: '#F79009' }}>
            <Typography variant="subtitle2">
              {data.startDate || data.defaultStartDate} - {data.startHour || data.defaultStartHour}
            </Typography>
            <Typography variant="subtitle2">{data.venue || data.defaultVenue}</Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container width="100%" height="20%" mt={2} mb={1}>
        <Grid item xs={12}>
          <Box sx={{ height: '100%', width: '100%', paddingLeft: '1rem', color: 'gray' }}>
            <Typography variant="subtitle2">{data.description || data.defaultDescription}</Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container width="100%" height="20%" pb={1}>
        <Grid item xs={12} sx={{ paddingLeft: '1rem' }}>
          <Typography variant="h6" style={{ color: 'white' }}>
            Ubicación
          </Typography>
          <Box sx={{ height: '100%', width: '100%', backgroundColor: '#092838', color: 'white', display: 'flex' }}>
            <Icon sx={{ display: 'flex-start', color: '#F79009' }}>place_outlined_icon</Icon>
            <Typography variant="subtitle2" color="gray">
              {data.address || data.defaultAddress}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default EventPreview
