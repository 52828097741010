import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import TicketControls from '../../molecules/TicketControls/TicketControls'

const TicketItem = ({ ticketNumber, name, price, count, onIncrement, onDecrement }) => (
  <Grid
    container
    mt={1}
    pl={2}
    pb={2}
    pt={2}
    sx={{ backgroundColor: '#092838', borderRadius: '10px' }}
    alignItems="center"
  >
    <Grid item xs={6}>
      <Box>
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          {name ? name : `Ticket N°${ticketNumber}`}
        </Typography>
        <Typography variant="body2" sx={{ color: '#fb8c00' }}>
          {price ? `$ ${parseInt(price).toLocaleString('es-CL')}` : '-'}
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={6} container justifyContent="flex-end" alignItems="center" pr={1}>
      <TicketControls count={count} onIncrement={onIncrement} onDecrement={onDecrement} />
    </Grid>
  </Grid>
)

export default TicketItem
