import {
  CreateEventPage,
  DashboardPage,
  EventDetailsPage,
  EventMetricsPage,
  EventsPage,
  LoginPage,
} from './components/pages'

import Icon from '@mui/material/Icon'
import UserManagementPage from './components/pages/UserManagementPage/UserManagement'
import LandingPage from './components/pages/LandingPage/LandingPage'

const routes = [
  {
    type: 'navItem',
    name: 'Dashboard',
    key: 'dashboard',
    icon: <Icon fontSize="small">home</Icon>,
    route: '/dashboard',
    component: <DashboardPage />,
  },
  {
    type: 'navItem',
    name: 'Eventos',
    key: 'events',
    icon: <Icon fontSize="small">event</Icon>,
    route: '/events',
    component: <EventsPage />,
  },
  {
    type: 'internal',
    name: 'Metricas del Evento',
    key: 'event-metrics',
    icon: <Icon fontSize="small">event</Icon>,
    route: '/events/:id/metrics',
    component: <EventMetricsPage />,
  },
  {
    type: 'internal',
    name: 'Detalles del Evento',
    key: 'event-detail',
    icon: <Icon fontSize="small">event</Icon>,
    route: '/events/:id/details',
    component: <EventDetailsPage />,
  },
  {
    type: 'internal',
    name: 'Creat tu evento',
    key: 'new-event',
    icon: <Icon fontSize="small">event</Icon>,
    route: '/events/new',
    component: <CreateEventPage />,
  },
  {
    type: 'auth',
    name: 'Login',
    key: 'login',
    icon: <Icon fontSize="small">login</Icon>,
    route: '/auth/Login',
    component: <LoginPage />,
  },
  {
    type: 'internal',
    name: 'Administración de usuarios',
    key: 'user-managemente',
    icon: <Icon fontSize="small">event</Icon>,
    route: '/users',
    component: <UserManagementPage />,
  },
  {
    type: 'internal',
    name: 'Mango Ticket',
    key: 'landing',
    icon: <Icon fontSize="small">event</Icon>,
    route: '/',
    component: <LandingPage />,
  },
  // {
  //   type: 'support',
  //   name: 'Soporte',
  //   key: 'support',
  //   icon: <Icon fontSize="small">help</Icon>,
  //   route: '/chart-details/:chartType',
  //   component: <ChartDetailsPage />,
  // },
  // {
  //   type: 'support',
  //   name: 'Configuración',
  //   key: 'configuration',
  //   icon: <Icon fontSize="small">settings</Icon>,
  //   route: '/chart-details/:chartType',
  //   component: <ChartDetailsPage />,
  // },
]

export default routes
