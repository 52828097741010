import React, { useState } from 'react'
import {
  Avatar,
  Box,
  Chip,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { statusColors, statusText } from '../../../utils/constants'
import { parseIsoDate } from '../../../utils/date'
import Menu from '@mui/material/Menu'
import { useNavigate } from 'react-router-dom'

export default function CustomerEventsTable({ height = '100%', events = [] }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedEventId, setSelectedEventId] = useState(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()

  const handleMenuOpen = (event, eventId) => {
    setAnchorEl(event.currentTarget)
    setSelectedEventId(eventId)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
    setSelectedEventId(null)
  }

  const handleViewMetrics = () => {
    handleMenuClose()
    navigate(`/events/${selectedEventId}/metrics`)
  }

  const handleViewDetails = () => {
    handleMenuClose()
    navigate(`/events/${selectedEventId}/details`)
  }
  return (
    <TableContainer component={Paper} sx={{ width: '100%', height }}>
      <Table sx={{ minWidth: 650, marginBottom: '10px' }} aria-label="customer events table">
        <TableHead sx={{ display: 'table-header-group' }}>
          <TableRow>
            <TableCell sx={{ width: '10%' }}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography variant="h6" fontWeight="bold" color="white"></Typography>
              </Box>
            </TableCell>
            <TableCell sx={{ width: '30%' }}>
              <Box display="flex" alignItems="left">
                <Typography variant="h6" fontWeight="bold" color="white">
                  Nombre
                </Typography>
              </Box>
            </TableCell>
            <TableCell sx={{ width: '30%' }}>
              <Box ml={4} display="flex" alignItems="left">
                <Typography variant="h6" fontWeight="bold" color="white">
                  Fecha
                </Typography>
              </Box>
            </TableCell>
            <TableCell sx={{ width: '20%' }}>
              <Box justifyContent="center" display="flex" alignItems="left">
                <Typography variant="h6" fontWeight="bold" color="white">
                  Estado
                </Typography>
              </Box>
            </TableCell>
            <TableCell sx={{ width: '10%' }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {events.map((event) => (
            <TableRow key={event.name}>
              <TableCell>
                <Box ml={3} display="flex" justifyContent="center" alignItems="center">
                  <Avatar
                    variant="square"
                    src={event.image}
                    alt={event.name}
                    sx={{ width: 65, height: 65, borderRadius: 3 }}
                  />
                </Box>
              </TableCell>
              <TableCell>
                <Box display="flex" alignItems="left">
                  <Typography variant="body1" fontWeight="bold">
                    {event.name}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box display="flex" alignItems="left">
                  <Typography variant="body1" color="textSecondary">
                    {parseIsoDate(event.start_at)}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box display="flex" justifyContent="center">
                  <Chip
                    label={statusText[event.status]}
                    color={statusColors[event.status]}
                    sx={{ width: '88px', height: '24px', borderRadius: '20px' }}
                  />
                </Box>
              </TableCell>
              <TableCell>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <IconButton
                    aria-label="more"
                    aria-controls={open ? 'event-menu' : undefined}
                    aria-haspopup="true"
                    onClick={(e) => handleMenuOpen(e, event.id)}
                  >
                    <MoreHorizIcon />
                  </IconButton>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Menu id="event-menu" anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
        <MenuItem onClick={handleViewMetrics}>Ver métricas</MenuItem>
        <MenuItem onClick={handleViewDetails}>Ver detalle</MenuItem>
      </Menu>
    </TableContainer>
  )
}
