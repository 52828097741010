// src/hooks/useUsersData.js

import { useEffect, useState } from 'react'
import { usersInMemory } from '../memoryDataStore'

export const useUsersData = () => {
  const [usersData, setUsersData] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const fetchUsersData = async () => {
    setLoading(true)
    try {
      // Simulamos una llamada a la API obteniendo los usuarios del almacenamiento en memoria
      setUsersData([...usersInMemory])
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchUsersData()
  }, [])

  return { usersData, loading, error, refetch: fetchUsersData }
}
