import React from 'react'
import { DatePicker } from '@mui/x-date-pickers'
import { FormTextField } from '../../atoms'

const DatePickerField = ({ label, ...props }) => {
  return (
    <DatePicker
      sx={{ width: '100%' }}
      label={label}
      renderInput={(params) => <FormTextField {...params} />}
      {...props}
      format="DD/MM/YYYY"
    />
  )
}

export default DatePickerField
