// src/components/UserManagementPage/UserManagementPage.js

import React, { useCallback, useState } from 'react'
import { Box, Button, Paper, Typography, useMediaQuery } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useUsersData } from '../../../hooks/userManagement/useUsersData'
import { LoadingSpinner } from '../../atoms'
import UserTable from '../../organisms/UserTable/UserTable'
import AddEditUserDialog from '../../organisms/AddEditUserDialog/AddEditUserDialog'
import SendCourtesiesDialog from '../../organisms/SendCourtesiesDialog/SendCourtesiesDialog'
import CSVUpload from '../../molecules/UserCSVUpload/UserCSVUpload' // Asegúrate de que la ruta sea correcta
import { DashboardLayout } from '../../templates'

const UserManagementPage = () => {
  const { usersData, loading, error, refetch } = useUsersData()
  const [open, setOpen] = useState(false)
  const [isSendCourtesiesDialogOpen, setIsSendCourtesiesDialogOpen] = useState(false)
  const [editingUser, setEditingUser] = useState(null)

  // Estado para usuarios seleccionados
  const [selectedUsers, setSelectedUsers] = useState([])

  // Para manejar la responsividad
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const handleOpen = useCallback(() => {
    setEditingUser(null)
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
    setEditingUser(null)
  }, [])

  const handleEditUser = useCallback((user) => {
    setEditingUser(user)
    setOpen(true)
  }, [])

  const handleOpenSendCourtesiesDialog = () => {
    setIsSendCourtesiesDialogOpen(true)
  }

  const handleCloseSendCourtesiesDialog = () => {
    setIsSendCourtesiesDialogOpen(false)
  }

  const handleSelectUser = (userId) => {
    setSelectedUsers((prevSelected) => {
      if (prevSelected.includes(userId)) {
        return prevSelected.filter((id) => id !== userId)
      } else {
        return [...prevSelected, userId]
      }
    })
  }

  const handleSelectAllUsers = (isAllSelected) => {
    if (isAllSelected) {
      const allUserIds = usersData.map((user) => user.id)
      setSelectedUsers(allUserIds)
    } else {
      setSelectedUsers([])
    }
  }

  if (loading) return <LoadingSpinner />

  if (error) {
    return (
      <Paper
        elevation={3}
        sx={{
          padding: '20px',
          backgroundColor: '#092838',
          color: '#fff',
          borderRadius: '10px',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            textAlign: 'center',
          }}
        >
          <Typography variant="h6" color="error">
            {error.message}
          </Typography>
          <Typography variant="body1">
            Por favor, intenta refrescar la página o contacta a soporte si el problema persiste.
          </Typography>
        </Box>
      </Paper>
    )
  }

  return (
    <DashboardLayout>
      <Paper
        elevation={3}
        sx={{
          padding: '20px',
          backgroundColor: '#092838',
          color: '#fff',
          borderRadius: '10px',
        }}
      >
        <Typography variant="h6" component="div" sx={{ color: 'white', fontWeight: 'bold', marginBottom: 2 }}>
          Gestión de Usuarios
        </Typography>

        <CSVUpload onUploadSuccess={refetch} />

        <Box
          display="flex"
          flexDirection={isSmallScreen ? 'column' : 'row'}
          justifyContent="space-between"
          alignItems={isSmallScreen ? 'flex-start' : 'center'}
          marginBottom={2}
          marginTop={2}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#F79009',
              color: 'white',
              borderRadius: '8px',
              fontWeight: 'bold',
              padding: '6px 12px',
              marginBottom: isSmallScreen ? '16px' : '0',
              alignSelf: isSmallScreen ? 'stretch' : 'auto',
            }}
            startIcon={<AddIcon />}
            onClick={handleOpen}
          >
            Agregar Usuario
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#F79009',
              color: 'white',
              borderRadius: '8px',
              fontWeight: 'bold',
              padding: '6px 12px',
              alignSelf: isSmallScreen ? 'stretch' : 'auto',
            }}
            onClick={handleOpenSendCourtesiesDialog}
            disabled={selectedUsers.length === 0}
          >
            Enviar Cortesías
          </Button>
        </Box>

        <Box
          sx={{
            flexShrink: 0,
            width: '100%',
            height: '1px',
            backgroundColor: '#041E2B',
            marginBottom: '16px',
          }}
        />

        <UserTable
          users={usersData}
          onEditUser={handleEditUser}
          refetchUsers={refetch}
          isSmallScreen={isSmallScreen}
          selectedUsers={selectedUsers}
          onSelectUser={handleSelectUser}
          onSelectAllUsers={handleSelectAllUsers}
        />

        <AddEditUserDialog open={open} onClose={handleClose} user={editingUser} refetchUsers={refetch} />

        <SendCourtesiesDialog
          open={isSendCourtesiesDialogOpen}
          onClose={handleCloseSendCourtesiesDialog}
          selectedUserIds={selectedUsers}
        />
      </Paper>
    </DashboardLayout>
  )
}

export default UserManagementPage
