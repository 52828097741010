// src/components/organisms/TicketSection.jsx
import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import TicketItem from '../../molecules/TicketItem/TicketItem'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as EditIcon } from '../../../assets/mango/icons/edit.svg'

const TicketSection = ({ sx = {} }) => {
  const [selectedTicketIndex, setSelectedTicketIndex] = useState(0)
  const handleTicketHover = (index) => {
    setSelectedTicketIndex(index)
  }
  const [tickets, setTickets] = useState([
    {
      id: 1,
      name: 'Preventa 1',
      description: 'Nominativa. Válido hasta las 01:00hrs',
      price: '$10.000',
      quantity: 2,
    },
    {
      id: 2,
      name: 'Preventa 2',
      description: 'Nominativa. Válido hasta las 01:00hrs',
      price: '$15.000',
      quantity: 0,
    },
    {
      id: 3,
      name: 'Entrada VIP',
      description: 'Nominativa. Incluye 2 Covers.',
      price: '$50.000',
      quantity: 0,
    },
  ])

  const handleIncrease = (id) => {
    setTickets((prevTickets) =>
      prevTickets.map((ticket) => (ticket.id === id ? { ...ticket, quantity: ticket.quantity + 1 } : ticket))
    )
  }

  const handleDecrease = (id) => {
    setTickets((prevTickets) =>
      prevTickets.map((ticket) =>
        ticket.id === id && ticket.quantity > 0 ? { ...ticket, quantity: ticket.quantity - 1 } : ticket
      )
    )
  }

  return (
    <Box
      sx={{
        borderRadius: '18px',
        backgroundColor: '#07181e',
        width: '100%',
        maxHeight: '408px',
        display: 'flex',
        flexDirection: 'column',
        ...sx,
      }}
    >
      {/* Título de la sección */}
      <Box
        sx={{
          padding: '21px 21px 0px', // Ajustamos el padding superior e inferior
          backgroundColor: 'inherit',
          position: 'sticky',
          top: 0,
          zIndex: 1,
          borderRadius: '18px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h2" sx={{ color: '#fff', marginBottom: '24px' }}>
          Tickets
        </Typography>
        <IconButton
          aria-label="Editar Tickets"
          sx={{ color: '#fff', marginBottom: '24px' }}
          onClick={() => {
            // Aquí puedes agregar la lógica para editar
          }}
        >
          <EditIcon />
        </IconButton>
      </Box>

      {/* Contenedor de los items con scroll */}
      <Box
        sx={{
          padding: '0px 21px 45px', // Ajustamos el padding lateral e inferior
          overflowY: 'auto',
        }}
      >
        {tickets.map((ticket, index) => (
          <TicketItem
            key={ticket.id}
            name={ticket.name}
            description={ticket.description}
            price={ticket.price}
            quantity={ticket.quantity}
            onIncrease={() => handleIncrease(ticket.id)}
            onDecrease={() => handleDecrease(ticket.id)}
            selected={selectedTicketIndex === index}
            onMouseEnter={() => handleTicketHover(index)}
          />
        ))}
      </Box>
    </Box>
  )
}

export default TicketSection
