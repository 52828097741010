import HttpService from './http.service'
import GraphqlService from './graphql.service'

class AuthService {
  // authEndpoint = process.env.API_URL;

  login = async (username, password) => {
    const source = 'google'
    const loginQuery = `
        mutation Login($input: LoginData!) {
            login(input: $input) {
                can_access
                user {
                    id
                    firstname
                    lastname
                    email
                    dni
                    gender
                    phone
                    birth_date
                    preferences
                    news_subscription
                    roles
                    resale_sign
                    resale_contract_url
                    picture
                    source
                    created_at
                    updated_at
                    deleted_at
                }

            }
        }
    `
    return await GraphqlService.request({
      query: loginQuery,
      variables: { input: { username, password, source } },
      operationName: 'Login',
    })
  }

  register = async (credentials) => {
    const registerEndpoint = 'register'
    return await HttpService.post(registerEndpoint, credentials)
  }

  logout = async () => {
    const logoutEndpoint = 'logout'
    return await HttpService.post(logoutEndpoint)
  }

  forgotPassword = async (payload) => {
    const forgotPassword = 'password-forgot'
    return await HttpService.post(forgotPassword, payload)
  }

  resetPassword = async (credentials) => {
    const resetPassword = 'password-reset'
    return await HttpService.post(resetPassword, credentials)
  }

  getProfile = async () => {
    const getProfile = 'me'
    return await HttpService.get(getProfile)
  }

  updateProfile = async (newInfo) => {
    const updateProfile = 'me'
    return await HttpService.patch(updateProfile, newInfo)
  }
}

export default new AuthService()
