import { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { Typography } from 'components/atoms'
import { ProgressStyle } from './Progress.style'

const Progress = forwardRef(({ variant = 'contained', color = 'info', value = 0, label = false, ...rest }, ref) => (
  <>
    {label && (
      <Typography variant="button" fontWeight="medium" color="text">
        {value}%
      </Typography>
    )}
    <ProgressStyle {...rest} ref={ref} variant="determinate" value={value} ownerState={{ color, value, variant }} />
  </>
))

Progress.propTypes = {
  variant: PropTypes.oneOf(['contained', 'gradient']),
  color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'light', 'dark']),
  value: PropTypes.number,
  label: PropTypes.bool,
}

export default Progress
