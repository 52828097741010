// src/components/AddEditUserDialog/AddEditUserDialog.js

import React, { useEffect, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, useMediaQuery } from '@mui/material'
import FormField from '../../molecules/FormField/FormField'
import { useCreateUser } from '../../../hooks/userManagement/useCreateUser'
import { useUpdateUser } from '../../../hooks/userManagement/useUpdateUser'

const AddEditUserDialog = ({ open, onClose, user, refetchUsers }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const [formData, setFormData] = useState({ name: '', email: '' })
  const { createUser } = useCreateUser()
  const { updateUser } = useUpdateUser()

  // Estado para manejar errores de validación
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (user) {
      setFormData({ name: user.name, email: user.email })
    } else {
      setFormData({ name: '', email: '' })
    }
  }, [user])

  const validate = () => {
    const newErrors = {}

    if (!formData.name.trim()) {
      newErrors.name = 'El nombre es requerido'
    }

    if (!formData.email.trim()) {
      newErrors.email = 'El correo es requerido'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
      newErrors.email = 'Ingresa un correo electrónico válido'
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSaveUser = async () => {
    if (validate()) {
      try {
        if (user) {
          await updateUser(user.id, formData)
        } else {
          await createUser(formData)
        }
        refetchUsers()
        onClose()
      } catch (error) {
        console.error(error)
        alert('Ocurrió un error al guardar el usuario.')
      }
    }
  }

  // Limpiar errores al cerrar el modal
  useEffect(() => {
    if (!open) {
      setErrors({})
    }
  }, [open])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: '#092838',
          borderRadius: '0.5 rem',
        },
      }}
    >
      <DialogTitle style={{ backgroundColor: '#092838', color: '#fff' }}>
        <span>{user ? 'Editar Usuario' : 'Agregar Usuario'}</span>
        <Box
          mt={1}
          sx={{
            flexShrink: 0,
            width: '100%',
            height: '1px',
            backgroundColor: '#041E2B !important',
          }}
        />
      </DialogTitle>
      <DialogContent style={{ backgroundColor: '#092838', color: '#fff' }}>
        <Box
          component="form"
          onSubmit={(e) => e.preventDefault()}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            marginTop: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormField
                label="Nombre"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                error={!!errors.name}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                label="Correo"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                error={!!errors.email}
                helperText={errors.email}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions style={{ backgroundColor: '#092838' }}>
        <Button onClick={onClose} style={{ color: '#F79009' }}>
          Cancelar
        </Button>
        <Button
          onClick={handleSaveUser}
          variant="contained"
          style={{
            backgroundColor: '#F79009',
            color: 'white',
            borderRadius: '8px',
            fontWeight: 'bold',
          }}
        >
          {user ? 'Guardar' : 'Agregar'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddEditUserDialog
