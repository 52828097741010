import Axios from 'axios'

const API_URL = process.env.REACT_APP_MANGO_DASHBOARD_API_URL

class GraphqlService {
  constructor() {
    this._axios = Axios.create({
      baseURL: API_URL,
      withCredentials: true,
    })

    this._axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true

          try {
            const newToken = await this.handleTokenRefresh()
            if (newToken) {
              originalRequest.headers['Authorization'] = `Bearer ${newToken}`
              return this._axios(originalRequest)
            } else {
              window.location.href = '/auth/login'
              return Promise.reject(error)
            }
          } catch (err) {
            window.location.href = '/auth/login'
            return Promise.reject(err)
          }
        }

        return Promise.reject(error)
      }
    )
  }

  async verifyAuth() {
    try {
      const response = await this.request({
        query: `
          query VerifyAuth {
              verifyAuth {
                  isAuthenticated
                  message
              }
          }
        `,
        operationName: 'VerifyAuth',
      })

      if (!response.verifyAuth) {
        window.location.href = '/auth/login'
      }
      return response.verifyAuth
    } catch (error) {
      // window.location.href = '/auth/login'
      // TODO: send event notification
    }
  }

  async request({ query, variables, operationName }) {
    try {
      const res = await this._axios.post(
        '/graphql',
        {
          query,
          variables,
          operationName,
        },
        {
          headers: {
            'x-user-platform': 'web',
          },
        }
      )

      if (res?.data?.errors) {
        throw res.data.errors
      }

      return res.data.data
    } catch (err) {
      throw err
    }
  }

  async handleTokenRefresh() {
    try {
      return await this.refreshToken()
    } catch (error) {
      return null
    }
  }

  async refreshToken() {
    try {
      const response = await this._axios.post('/graphql', {
        query: `
          mutation RefreshToken {
            refreshToken {
              success
              token
              message
            }
          }
        `,
        operationName: 'RefreshToken',
      })

      const { success, token, message } = response.data.data.refreshToken

      if (!success) {
        throw new Error(message)
      }

      return token
    } catch (error) {
      throw error
    }
  }
}

export default new GraphqlService()
