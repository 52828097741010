// src/components/templates/EventDetailTemplate.jsx
import React from 'react'
import { Box } from '@mui/material'
import StyledImage from '../../atoms/StyledImage/StyledImage'
import EventInfoSection from '../../organisms/EventInfoSection/EventInfoSection'
import TicketSection from '../../organisms/TicketSection/TicketSection'
import BarSection from '../../organisms/BarSection/BarSection'

const EventDetailTemplate = () => (
  <Box
    sx={{
      //background: 'linear-gradient(to bottom, #04121a, #041e2b)',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      minHeight: '100vh',
    }}
  >
    {/*<Header />*/}
    <Box
      sx={{
        alignSelf: 'center',
        display: 'flex',
        width: '100%',
        maxWidth: '1259px',
        // padding: { xs: '0 20px', md: '0 40px' },
        flexDirection: { xs: 'column', md: 'row' },
        gap: '22px',
        marginTop: '59px',
        flexGrow: 1,
      }}
    >
      {/* Left Column */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '23px',
          alignItems: { xs: 'center', md: 'flex-start' },
        }}
      >
        {/* Event Image */}
        <StyledImage
          src="https://cdn.builder.io/api/v1/image/assets/8d11d8ff049440d581f10d53cffd98cc/8e8501e034e9d088d19185cca747c65654b17d28fd38ca293f0c864434def621"
          alt="Event Image"
          sx={{
            borderRadius: '18px',
            boxShadow: '0 -14px 14px rgba(0, 0, 0, 0.05)',
            width: '100%',
            maxWidth: '398px',
            maxHeight: '408px',
            objectFit: 'cover',
            margin: { xs: '0 auto', md: '0' },
          }}
        />

        {/* Event Info */}
        <EventInfoSection
          sx={{
            maxWidth: '398px',
            flexGrow: 1,
          }}
        />
      </Box>

      {/* Right Column */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '23px',
        }}
      >
        {/* Tickets */}
        <TicketSection
          sx={{
            maxWidth: '839px',
            maxHeight: '408px',
            flexGrow: 1,
          }}
        />

        {/* Bar */}
        <BarSection
          sx={{
            maxWidth: '839px',
            maxHeight: '583px',
            flexGrow: 1,
          }}
        />
      </Box>
    </Box>
  </Box>
)

export default EventDetailTemplate
