// src/hooks/userManagement/useSendCourtesies.js

import { courtesiesInMemory } from '../memoryDataStore'

export const useSendCourtesies = () => {
  const sendCourtesies = async (userIds, eventId) => {
    const newCourtesies = userIds.map((userId) => ({
      id: `courtesy-${Date.now()}-${userId}`,
      userId,
      eventId,
      sentAt: new Date(),
    }))

    courtesiesInMemory.push(...newCourtesies)
  }

  return { sendCourtesies }
}
