import { useCallback, useContext, useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import { LoginPage } from './components/pages'
import { ProtectedRoute } from 'components/organisms'
import theme from 'assets/theme'
import routes from 'routes'
import { AuthContext, setLayout, setMiniSidenav, setOpenConfigurator, useMangoController } from 'context'
import Box from '@mui/material/Box'
import NotFound from './components/pages/NotFoundPage/NotFoundPage'

export default function App() {
  const authContext = useContext(AuthContext)

  const [controller, dispatch] = useMangoController()
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller
  const [onMouseEnter, setOnMouseEnter] = useState(false)
  const { pathname } = useLocation()

  const handleOnMouseEnter = useCallback(() => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false)
      setOnMouseEnter(true)
    }
  }, [miniSidenav, onMouseEnter, dispatch])

  const handleOnMouseLeave = useCallback(() => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true)
      setOnMouseEnter(false)
    }
  }, [onMouseEnter, dispatch])

  const handleConfiguratorOpen = useCallback(() => {
    setOpenConfigurator(dispatch, !openConfigurator)
  }, [openConfigurator, dispatch])

  const navigate = useNavigate()

  useEffect(() => {
    document.body.setAttribute('dir', direction)
  }, [direction])

  useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
  }, [pathname])

  const routeExists = useCallback((pathname, routes) => {
    return routes.some((route) => {
      if (route.collapse) {
        return routeExists(pathname, route.collapse)
      }
      if (route.route) {
        const routePattern = route.route.replace(/:\w+/g, '[^/]+')
        const regex = new RegExp(`^${routePattern}$`)
        return regex.test(pathname)
      }
      return false
    })
  }, [])

  useEffect(() => {
    if (pathname === '/') {
      setLayout(dispatch, 'landing')
    } else if (pathname === '/auth/login') {
      setLayout(dispatch, 'auth')
    } else if (routeExists(pathname, routes)) {
      setLayout(dispatch, 'home')
    } else {
      setLayout(dispatch, 'plain')
    }
  }, [pathname, dispatch, routeExists])

  const getRoutes = useCallback(
    (allRoutes) => {
      return allRoutes.map((route) => {
        if (route.collapse) {
          return getRoutes(route.collapse)
        }

        if (route.route && route.type !== 'auth') {
          return (
            <Route
              exact
              path={route.route}
              element={
                route.key === 'landing' ? (
                  <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>{route.component}</ProtectedRoute>
                ) : (
                  // route.component
                  <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>{route.component}</ProtectedRoute>
                )
              }
              key={route.key}
            />
          )
        }
        return null
      })
    },
    [authContext.isAuthenticated]
  )

  return (
    <>
      {
        <Helmet>
          <meta name="keywords" content="Mango Ticket, Mango Ticket Dashboard, Mango Ticket" />
          <meta name="description" content="Mango Ticket Support Dashboard" />
          <meta itemProp="name" content="Admin Dashboard" />
          <meta itemProp="description" content="Mango Ticket Support Dashboard" />
          <meta itemProp="image" content="https://www.mangoticket.com/images/mangoticket-logo.png" />
          <meta name="twitter:card" content="product" />
          <meta name="twitter:site" content="@me" />
          <meta name="twitter:title" content="Admin Dashboard" />
          <meta name="twitter:description" content="Mango Ticket Support Dashboard" />
          <meta name="twitter:creator" content="@me" />
          <meta name="twitter:image" content="https://www.mangoticket.com/images/mangoticket-logo.png" />
          <meta property="fb:app_id" content="" />
          <meta property="og:title" content="Admin Dashboard" />
          <meta property="og:type" content="article" />
          <meta property="og:url" content="https://www.mangoticket.com" />
          <meta property="og:image" content="https://www.mangoticket.com/images/mangoticket-logo.png" />
          <meta property="og:description" content="Mango Ticket Support Dashboard" />
          <meta property="og:site_name" content="Mango Ticket" />
        </Helmet>
      }
      {
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {/*{layout !== 'plain' && layout !== 'auth' && layout !== 'landing' && (*/}
          {/*  <SideNav*/}
          {/*    color={sidenavColor}*/}
          {/*    routes={routes}*/}
          {/*    onMouseEnter={handleOnMouseEnter}*/}
          {/*    onMouseLeave={handleOnMouseLeave}*/}
          {/*  />*/}
          {/*)}*/}
          <MainContent miniSidenav={miniSidenav} layout={layout}>
            <Routes>
              <Route path="/auth/login" element={<LoginPage />} />
              {getRoutes(routes)}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </MainContent>
        </ThemeProvider>
      }
    </>
  )
}

function MainContent({ children, miniSidenav, layout }) {
  const sidebarWidth = miniSidenav ? 96 : 250

  return (
    <Box
      component="main"
      sx={{
        background: 'linear-gradient(to bottom, #04121a, #041e2b)',
      }}
      // sx={{
      //   marginLeft: layout === 'home' ? `${window.innerWidth < 1200 ? sidebarWidth : 0}px` : '0px',
      //   transition: 'margin-left 0.3s ease',
      // }}
    >
      {children}
    </Box>
  )
}
