// Hero.js
import React from 'react'
import { Box, Button, Container, Typography } from '@mui/material'

const Hero = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#041e2b',
        pb: { xs: 0, md: 0 },
        px: { xs: 2, md: 8 },
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ maxWidth: { xs: '100%', md: '768px' }, mx: 'auto' }}>
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: '40px', md: '70px' },
              lineHeight: 1.2,
              fontWeight: 700,
              mb: 3,
            }}
          >
            Simplifica tus eventos. <br />
            <Box component="span" sx={{ color: '#fff' }}>
              Mejora la experiencia.
            </Box>
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: '16px', md: '18px' },
              lineHeight: 1.5,
              color: '#eee',
              mb: 4,
              maxWidth: '600px',
              mx: 'auto',
            }}
          >
            Gestiona entradas y productos de barra en un solo lugar, ofreciendo a tus asistentes una experiencia sin
            interrupciones.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              justifyContent: 'center',
              flexWrap: 'wrap',
              mb: { xs: 4, md: 8 },
            }}
          >
            <Button
              variant="contained"
              href="/events/new"
              sx={{
                backgroundColor: '#f79009',
                color: '#fff',
                borderRadius: '10px',
                px: 3,
                py: 1.5,
                fontSize: '16px',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#e67e00',
                },
              }}
            >
              Crea tu evento
            </Button>
            <Button
              variant="outlined"
              sx={{
                color: '#fff',
                borderColor: '#f79009',
                borderRadius: '10px',
                px: 3,
                py: 1.5,
                fontSize: '16px',
                textTransform: 'none',
                '&:hover': {
                  borderColor: '#e67e00',
                },
              }}
            >
              Descubre
            </Button>
          </Box>
        </Box>
        <Box
          component="img"
          src="https://cdn.builder.io/api/v1/image/assets/8d11d8ff049440d581f10d53cffd98cc/1534cc1973c463e26f8451932778f9f92975d22d4581bfc0849712b8a87de27a?apiKey=8d11d8ff049440d581f10d53cffd98cc&"
          alt="MangoTicket Event Management"
          sx={{
            maxWidth: '100%',
            height: 'auto',
            mt: { xs: 4, md: 4 },
          }}
        />
      </Container>
    </Box>
  )
}

export default Hero
