import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import { setLayout, useMangoController } from 'context'
import { Footer } from 'components/organisms'
import { Box } from 'components/atoms'

function DashboardLayout({ children }) {
  const [controller, dispatch] = useMangoController()
  const { miniSidenav } = controller
  const { pathname } = useLocation()

  useEffect(() => {
    setLayout(dispatch, 'home')
  }, [pathname])

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100vh"
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: 'relative',

        [breakpoints.up('xl')]: {
          // with sidebar use marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          marginLeft: miniSidenav ? pxToRem(60) : pxToRem(150),
          marginRight: miniSidenav ? pxToRem(60) : pxToRem(150),
          transition: transitions.create(['margin-left', 'margin-right'], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      <Box sx={{ flexGrow: 1 }}>{children}</Box>
      <Box mt={3}>
        <Footer />
      </Box>
    </Box>
  )
}

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DashboardLayout
