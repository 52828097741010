import { useEffect, useState } from 'react'
import { fetchEvents } from 'services/events-service'

const useFetchEventsData = (filter = null) => {
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    const getEvents = async () => {
      setLoading(true)
      try {
        const data = await fetchEvents(filter)
        setEvents(data?.getCustomerEvents)
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }

    if (refresh) {
      getEvents()
      setRefresh(false)
    }
  }, [refresh])

  return { events, loading, error, refreshData: () => setRefresh(true) }
}

export default useFetchEventsData
