import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { fetchCustomerDetails } from '../../../services/customer-service'
import { BoxPageLayout, DashboardLayout, ErrorBoundary } from '../../templates'
import { Typography } from '../../atoms'

function CustomerProfileContent({ id }) {
  const [customer, setCustomer] = useState(null)

  useEffect(() => {
    const getCustomerDetails = async () => {
      try {
        const data = await fetchCustomerDetails(id)
        setCustomer(data)
      } catch (error) {
        console.error('Failed to fetch customer details:', error)
      }
    }

    getCustomerDetails()
  }, [id])

  if (!customer) {
    return <Typography>Cargando...</Typography>
  }

  return (
    <>
      <Typography variant="h4" fontWeight="bold">
        {customer.name}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>Email:</strong> {customer.email}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>Teléfono:</strong> {customer.phone}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>Dirección:</strong> {customer.address}
      </Typography>
    </>
  )
}

function CustomerProfilePage() {
  const { id } = useParams()

  return (
    <DashboardLayout>
      <BoxPageLayout title="Perfil Cliente" color="info" route={-1}>
        <ErrorBoundary>
          <CustomerProfileContent id={id} />
        </ErrorBoundary>
      </BoxPageLayout>
    </DashboardLayout>
  )
}

export default CustomerProfilePage
