import React, { useCallback, useRef, useState } from 'react'
import { IconButton, Menu, MenuItem, TableCell, TableRow, Typography } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PositionSelect from '../../molecules/PositionSelect/PositionSelect'
import useUpdateCashier from '../../../hooks/eventDetails/useUpdateCashier'

const CashierTableRow = React.memo(({ worker, onEdit, onDelete, onChangePassword, isSmallScreen }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [role, setRole] = useState(worker.role)
  const { updateCashierData } = useUpdateCashier()

  const pendingActionRef = useRef(null)

  const handleMenuClick = useCallback((event) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleRoleChange = useCallback(
    async (e) => {
      const newRole = e.target.value
      setRole(newRole)
      try {
        await updateCashierData({ ...worker, role: newRole })
      } catch (err) {
        console.error(err)
        alert('Ocurrió un error al actualizar el cargo.')
      }
    },
    [worker, updateCashierData]
  )

  // Modify click handlers to use pendingActionRef
  const handleEditClick = useCallback(() => {
    pendingActionRef.current = () => onEdit(worker)
    handleMenuClose()
  }, [onEdit, worker, handleMenuClose])

  const handleDeleteClick = useCallback(() => {
    pendingActionRef.current = () => onDelete(worker.id)
    handleMenuClose()
  }, [onDelete, worker.id, handleMenuClose])

  const handleChangePasswordClick = useCallback(() => {
    pendingActionRef.current = () => onChangePassword(worker)
    handleMenuClose()
  }, [onChangePassword, worker, handleMenuClose])

  // Execute pending action after menu has exited
  const handleMenuExited = () => {
    if (pendingActionRef.current) {
      pendingActionRef.current()
      pendingActionRef.current = null
    }
  }

  return (
    <TableRow key={worker.id} style={{ backgroundColor: '#092838' }}>
      <TableCell style={{ color: 'white', padding: '8px', borderBottom: 'none' }}>
        <Typography variant="h6" fontWeight="normal" color="white !important">
          {worker.name}
        </Typography>
      </TableCell>
      {!isSmallScreen && (
        <TableCell style={{ color: '#fff', padding: '8px', borderBottom: 'none' }}>{worker.email}</TableCell>
      )}
      <TableCell style={{ padding: '8px', borderBottom: 'none' }}>
        <PositionSelect value={role} onChange={handleRoleChange} name="role" />
      </TableCell>
      <TableCell style={{ padding: '8px', textAlign: 'center', borderBottom: 'none' }}>
        <IconButton onClick={handleMenuClick} style={{ color: '#fff' }}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          disableScrollLock
          TransitionProps={{
            onExited: handleMenuExited,
          }}
        >
          <MenuItem onClick={handleEditClick}>Editar</MenuItem>
          <MenuItem onClick={handleChangePasswordClick}>Cambiar Contraseña</MenuItem>
          <MenuItem onClick={handleDeleteClick}>Eliminar</MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  )
})

export default CashierTableRow
