import React from 'react'

import { Box } from 'components/atoms'

const ErrorComponent = ({ message }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
      <p>{message}</p>
    </Box>
  )
}

export default ErrorComponent
