import React, { memo, useEffect, useRef } from 'react'
import { Chart as ChartJS, registerables } from 'chart.js'
import PropTypes from 'prop-types'

ChartJS.register(...registerables)

const Chart = ({ type, data, options, onLabelClick }) => {
  const chartRef = useRef(null)
  const currentLabelRef = useRef(null)

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d')

    const myChart = new ChartJS(ctx, {
      type: type,
      data: data,
      options: {
        ...options,
        onClick: (e) => {
          const points = myChart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true)

          if (points.length) {
            const firstPoint = points[0]
            const label = myChart.data.labels[firstPoint.index]
            const value = myChart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index]
            if (label !== currentLabelRef.current) {
              currentLabelRef.current = label
              onLabelClick({ label, value })
            }
          }
        },
      },
    })

    return () => {
      myChart.destroy()
    }
  }, [type, data, options, onLabelClick])

  return <canvas ref={chartRef} />
}

Chart.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  onLabelClick: PropTypes.func.isRequired,
}

export default memo(Chart)
