// src/hooks/useEventsData.js

import { useEffect, useState } from 'react'
import { eventsInMemory } from '../memoryDataStore'

export const useEventsData = () => {
  const [eventsData, setEventsData] = useState([])

  const fetchEventsData = async () => {
    // Simulamos la obtención de datos desde el almacenamiento en memoria
    setEventsData([...eventsInMemory])
  }

  useEffect(() => {
    fetchEventsData()
  }, [])

  return { eventsData }
}
