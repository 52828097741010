import React from 'react'
import { DashboardLayout, DashboardNavbar } from '../../templates'
import { Events } from '../../organisms'
import { Box } from '../../atoms'

const EventsPage = () => {
  return (
    <DashboardLayout>
      <Box py={3}>
        <DashboardNavbar absolute title="Eventos" />
      </Box>
      <Box mt={2} mb={4}>
        <hr
          style={{
            height: '1px',
            border: 'none',
            backgroundColor: '#667085',
          }}
        />
      </Box>
      <Events title="Mis Eventos" height="100%" />
    </DashboardLayout>
  )
}

export default EventsPage
