import GraphqlService from './graphql.service'

export const fetchEventDetails = async (id) => {
  const query = `
  query GetEventById($id: String!) {
    getEventById(id: $id) {
      event {
        id
        name
        description
        place
        start_at
        end_at
        start_hour
        end_hour
        active
        image
        categories
        out_of_stock
        created_at
        updated_at
        deleted_at
        status
        address
      }
    }
}
  `

  const variables = {
    id,
  }
  return await GraphqlService.request({ query, variables, operationName: 'GetEventById' })
  const getEventById = `/events/${id}`
  return 'not implemented'
}

export const updateEventDetails = async (id, updatedEvent) => {
  const updateEvent = `/events/${id}`
  return 'not implemented'
}

export const fetchEventItems = async (eventId) => {
  const getEventItems = `/events/${eventId}/items`
  return 'not implemented'
}

export const updateEventItem = async (eventId, itemId, updatedItem) => {
  const updateEventItem = `/events/${eventId}/items/${itemId}`
  return 'not implemented'
}

export const fetchEvents = async () => {
  const query = `
  query GetCustomerEvents {
    getCustomerEvents {
        id
        image
        name
        start_at
        end_at
        start_hour
        end_hour
        status
    }
  }
`
  return await GraphqlService.request({ query })
}

export const updateEventStatus = async (eventId, status) => {
  const updateStatus = `/events/${eventId}?status=${status}`
  return 'not implemented'
}

export const deleteEvent = async (eventId) => {
  const deleteEvent = `/events/${eventId}`
  return 'not implemented'
}

export const createEvent = async (event) => {
  const query = `
  mutation CreateEvent($input: CreateEventData!) {
    createEvent(input: $input) {
        id
        name
        description
        place
        start_at
        active
        image
        categories
        out_of_stock
        created_at
        updated_at
        deleted_at
        address
    }
}
  `

  const variables = {
    input: {
      name: event.name,
      place: event.place,
      description: event.description,
      image: event.image,
      start_at: event.start_at,
      end_at: event.end_at,
      start_hour: event.start_hour,
      end_hour: event.end_hour,
      nominated: event.nominated,
      address: event.address,
    },
  }
  return await GraphqlService.request({ query, variables, operationName: 'CreateEvent' })
}

export const batchCreateEventItem = async (eventItems, eventId) => {
  const query = `
mutation BatchCreateEventItem($input: [CreateEventItemData!]!) {
    batchCreateEventItem(input: $input) {
        id
        event_id
        name
        description
        type
        priority
        stock
        max_per_sale
        price
        resale
        end_at
        end_hour
        created_at
        updated_at
        deleted_at
    }
}

`

  const variables = {
    input: eventItems.map((item) => ({
      event_id: eventId,
      name: item.name,
      description: item.description,
      type: item.type,
      priority: item.priority,
      stock: item.stock,
      max_per_sale: 5,
      price: item.price,
      end_at: item.endDate,
      end_hour: item.endHour,
      image: item?.image,
    })),
  }
  console.log('variables', variables)

  return await GraphqlService.request({ query, variables, operationName: 'BatchCreateEventItem' })
}
