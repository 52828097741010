// Footer.js
import React from 'react'
import { Box, Button, Container, Typography } from '@mui/material'

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#041e2b',
        padding: { xs: '80px 20px', md: '80px 64px' },
      }}
    >
      <Container maxWidth="lg">
        {/* Contenido del Footer */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '80px',
          }}
        >
          <Box
            sx={{
              maxWidth: '560px',
              textAlign: 'center',
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: '40px', md: '70px' },
                lineHeight: 1.2,
                marginBottom: '24px',
              }}
            >
              Simplifica tus eventos. <br />
              <Box component="span" sx={{ color: '#fff' }}>
                Cámbiate a Mango
              </Box>
            </Typography>
            <Typography
              sx={{
                color: '#aaa',
                fontSize: '16px',
                marginBottom: '32px',
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum
              tristique.
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: '16px',
                flexWrap: 'wrap',
              }}
            >
              <Button
                variant="contained"
                href="/events/new"
                sx={{
                  backgroundColor: '#f79009',
                  color: '#fff',
                  borderRadius: '10px',
                  padding: '12px 24px',
                  fontSize: '16px',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#e67e00',
                  },
                }}
              >
                Crear Evento
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: '#fff',
                  borderColor: '#f79009',
                  borderRadius: '10px',
                  padding: '12px 24px',
                  fontSize: '16px',
                  textTransform: 'none',
                  '&:hover': {
                    borderColor: '#e67e00',
                  },
                }}
              >
                Hablemos
              </Button>
            </Box>
          </Box>
        </Box>
        {/* Créditos del Footer */}
        <Box
          sx={{
            borderTop: '1px solid #444',
            paddingTop: '32px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '32px',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center',
              gap: { xs: '20px', md: '0' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '20px',
                justifyContent: { xs: 'center', md: 'flex-start' },
              }}
            >
              <Box
                component="img"
                src="https://cdn.builder.io/api/v1/image/assets/8d11d8ff049440d581f10d53cffd98cc/1674301a91607cc22a7b7ea5d5a57559b6be05e6e3274d4fd73d574d6c5834c5?apiKey=8d11d8ff049440d581f10d53cffd98cc&"
                alt="MangoTicket Logo"
                sx={{
                  height: '40px',
                }}
              />
              <Box
                component="img"
                src="https://cdn.builder.io/api/v1/image/assets/8d11d8ff049440d581f10d53cffd98cc/a5496c7c6daa285742a37223ea23187c9e21ecfe54ef24a7f59813b46ee409e1?apiKey=8d11d8ff049440d581f10d53cffd98cc&"
                alt="Partner Logo"
                sx={{
                  height: '40px',
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '12px',
              }}
            >
              <a href="#" aria-label="Facebook">
                <Box
                  component="img"
                  src="https://cdn.builder.io/api/v1/image/assets/8d11d8ff049440d581f10d53cffd98cc/40cb2b9e78cdc93e6e4b12d4fc3cabd7ce3bce607207fca6674b61f6aea97784?apiKey=8d11d8ff049440d581f10d53cffd98cc&"
                  alt="Facebook"
                  sx={{
                    width: '24px',
                    height: '24px',
                  }}
                />
              </a>
              <a href="#" aria-label="Twitter">
                <Box
                  component="img"
                  src="https://cdn.builder.io/api/v1/image/assets/8d11d8ff049440d581f10d53cffd98cc/d19ce47a42cc14cb4259bd1e4fae22c910c759e882a5c8142040e080be3aa9ed?apiKey=8d11d8ff049440d581f10d53cffd98cc&"
                  alt="Twitter"
                  sx={{
                    width: '24px',
                    height: '24px',
                  }}
                />
              </a>
              <a href="#" aria-label="Instagram">
                <Box
                  component="img"
                  src="https://cdn.builder.io/api/v1/image/assets/8d11d8ff049440d581f10d53cffd98cc/38f27525fb8a8bb2d58baa34b9375965a593e1ac15b0de0bddd9e58d8d092ece?apiKey=8d11d8ff049440d581f10d53cffd98cc&"
                  alt="Instagram"
                  sx={{
                    width: '24px',
                    height: '24px',
                  }}
                />
              </a>
            </Box>
          </Box>
          <Typography
            sx={{
              color: '#aaa',
              fontSize: '14px',
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            © 2025 Mango Ticket SpA. Todos los derechos reservados.
          </Typography>
        </Box>
      </Container>
    </Box>
  )
}

export default Footer
