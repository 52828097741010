import React from 'react'
import { DashboardLayout, DashboardNavbar } from '../../templates'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TotalSales from '../../organisms/TotalSales/TotalSales'
import TicketSales from '../../organisms/TicketSales/TicketSales'
import EventData from '../../organisms/EventData/EventData'
import BarSales from '../../organisms/BarSales/BarSales'
import { useParams } from 'react-router-dom'
import CashierTable from '../../organisms/CashierTable/CashierTable'
import useFetchEventDataById from '../../../hooks/events/useFetchEventDataById'
import { LoadingSpinner } from '../../atoms'
import { Typography } from '@mui/material'

function EventMetricsPage() {
  const { id } = useParams()
  const [initialLoad, setInitialLoad] = React.useState(false)
  const { data, loading, error, refreshData } = useFetchEventDataById(id)
  console.log(data)
  if (!initialLoad) {
    refreshData()
    setInitialLoad(true)
  }

  if (loading) return <LoadingSpinner />

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          textAlign: 'center',
          backgroundColor: 'inherit',
          borderRadius: '10px',
        }}
      >
        <Typography variant="h6" color="error">
          {error.message}
        </Typography>
        <Typography variant="body1">
          Por favor, intenta refrescar la página o contacta a soporte si el problema persiste.
        </Typography>
      </Box>
    )
  }

  return (
    <DashboardLayout>
      <Box py={3}>
        <DashboardNavbar absolute title={data?.name} showBackButton />
      </Box>
      <Box mt={2} mb={4}>
        <hr
          style={{
            height: '1px',
            border: 'none',
            backgroundColor: '#667085',
          }}
        />
      </Box>
      <Box sx={{ backgroundColor: 'inherit' }}>
        <Grid container spacing={4} alignItems="stretch">
          <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
            <TotalSales eventId={id} />
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
            <EventData data={data} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TicketSales eventId={id} />
          </Grid>
          <Grid item xs={12} md={6}>
            <BarSales eventId={id} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CashierTable eventId={id} />
          </Grid>
        </Grid>
      </Box>
    </DashboardLayout>
  )
}

export default EventMetricsPage
