// Benefits.js
import React from 'react'
import { Box, Button, Container, Typography } from '@mui/material'

const BenefitSection = ({ tagline, title, description, imageUrl, altText, isReversed }) => {
  const titleParts = title.split('.')
  const titleFirstPart = titleParts[0] + '.'
  const titleSecondPart = titleParts[1]

  return (
    <Box
      component="section"
      sx={{
        backgroundColor: '#04121a',
        padding: { xs: '100px 20px', md: '112px 64px' },
        display: 'flex',
        alignItems: 'center',
        gap: { xs: '40px', md: '80px' },
        flexDirection: isReversed ? { xs: 'column', md: 'row-reverse' } : { xs: 'column', md: 'row' },
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: { xs: '40px', md: '80px' },
            flexDirection: { xs: 'column', md: isReversed ? 'row-reverse' : 'row' },
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography
              sx={{
                color: '#f79009',
                fontSize: '16px',
                fontWeight: 600,
                marginBottom: '16px',
              }}
            >
              {tagline}
            </Typography>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: '40px', md: '48px' },
                lineHeight: 1.2,
                marginBottom: '24px',
              }}
            >
              {titleFirstPart}{' '}
              <Box component="span" sx={{ color: '#fff' }}>
                {titleSecondPart}
              </Box>
            </Typography>
            <Typography
              sx={{
                color: '#aaa',
                fontSize: '18px',
                marginBottom: '32px',
              }}
            >
              {description}
            </Typography>
            <Box sx={{ display: 'flex', gap: '24px', flexWrap: 'wrap' }}>
              <Button
                variant="contained"
                href="/events/new"
                sx={{
                  backgroundColor: '#f79009',
                  color: '#fff',
                  borderRadius: '10px',
                  padding: '12px 24px',
                  fontSize: '16px',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#e67e00',
                  },
                }}
              >
                Crea tu evento
              </Button>
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: 'transparent',
                  color: '#fff',
                  borderColor: '#f79009',
                  borderRadius: '10px',
                  padding: '12px 24px',
                  fontSize: '16px',
                  textTransform: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  '&:hover': {
                    borderColor: '#e67e00',
                  },
                }}
              >
                Contáctanos
                <Box
                  component="img"
                  src="https://cdn.builder.io/api/v1/image/assets/8d11d8ff049440d581f10d53cffd98cc/63f55505ab252fa0d28727ed03e803a3eb080ef933b45d3067e3814df0562ac6?apiKey=8d11d8ff049440d581f10d53cffd98cc&"
                  alt="Contact icon"
                  sx={{
                    width: '24px',
                    height: '24px',
                    marginLeft: '8px',
                  }}
                />
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              component="img"
              src={imageUrl}
              alt={altText}
              loading="lazy"
              sx={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

const Benefits = () => {
  const benefitsData = [
    {
      tagline: 'Eficiencia',
      title: 'Facilita el acceso. Aumenta tus ventas.',
      description: 'Tus asistentes tendrán sus entradas y tragos en un solo lugar.',
      imageUrl:
        'https://cdn.builder.io/api/v1/image/assets/8d11d8ff049440d581f10d53cffd98cc/54f0d644e41fc097304674081debca2c7e02e4deac451a5cff376ff8a4e2834d?apiKey=8d11d8ff049440d581f10d53cffd98cc&',
      altText: 'Easy access to tickets and drinks',
    },
    {
      tagline: 'Fluidez',
      title: 'Todo en un solo código. Facil y accesible.',
      description:
        'Ofrece a tus asistentes la facilidad de gestionar sus entradas y tragos directamente desde su celular con un solo QR.',
      imageUrl:
        'https://cdn.builder.io/api/v1/image/assets/8d11d8ff049440d581f10d53cffd98cc/8c6dd266cf0ee0d11cc66b6c897dc4f19b7e5f807996a9965ef2aee5079c0a6c?apiKey=8d11d8ff049440d581f10d53cffd98cc&',
      altText: 'Single QR code for tickets and drinks',
    },
  ]

  return (
    <>
      {benefitsData.map((benefit, index) => (
        <BenefitSection key={index} {...benefit} isReversed={index % 2 !== 0} />
      ))}
    </>
  )
}

export default Benefits
