import React from 'react'
import SelectAtom from '../../atoms/Select/Select'

const positions = [
  { displayName: 'Portero', value: 'doorman' },
  { displayName: 'Barra', value: 'barman' },
  { displayName: 'Cajero', value: 'cashier' },
]

const PositionSelect = ({ value, onChange, name, error, helperText }) => {
  return (
    <SelectAtom
      options={positions}
      value={value}
      onChange={onChange}
      placeholder="Cargo"
      name={name}
      error={error}
      helperText={helperText}
    />
  )
}

export default PositionSelect
