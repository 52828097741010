import React, { useCallback, useState } from 'react'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import { ReactComponent as AddCircleIcon } from '../../../assets/mango/icons/add-circle-outline.svg'
import CSVUploader from '../../molecules/CSVUploader/CSVUploader'
import BarFormItem from '../BarFormItem/BarFormItem'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

const BarForm = ({ bar, setBar }) => {
  const [expandedBarId, setExpandedBarId] = useState(bar.length > 0 ? bar[0].id : null)
  const [nextBarId, setNextBarId] = useState(bar.length + 1) // Para asegurar IDs únicos

  const handleAddBar = () => {
    const newBarId = nextBarId
    const newBar = { id: newBarId, data: {}, priority: bar.length }
    setBar([...bar, newBar])
    setNextBarId(nextBarId + 1)
    setExpandedBarId(newBarId)
  }

  const handleDeleteBar = (barId) => {
    const newBars = bar.filter((item) => item.id !== barId)
    setBar(newBars)
    if (expandedBarId === barId) {
      setExpandedBarId(null)
    }
  }

  const handleFileUpload = (fileContent, fileName) => {
    const newBars = parseCSV(fileContent)
    setBar([...bar, ...newBars])
    setNextBarId(nextBarId + newBars.length)
  }

  const parseCSV = (csvContent) => {
    const separator = csvContent.includes(';') ? ';' : ','

    const lines = csvContent.trim().split('\n')
    const headers = lines[0].split(separator)

    const keyMap = {
      nombre: 'name',
      precio: 'price',
      cantidad: 'quantity',
      descripcion: 'description',
    }

    const items = lines.slice(1).map((line, index) => {
      const values = line.split(separator)
      const itemData = {}
      headers.forEach((header, idx) => {
        const key = keyMap[header.trim().toLowerCase()] || header.trim()
        itemData[key] = values[idx]?.trim() || ''
      })

      if (itemData.price) {
        const number = parseInt(itemData.price, 10)
        if (!isNaN(number)) {
          itemData.formattedPrice = '$ ' + number.toLocaleString('es-CL')
        } else {
          itemData.formattedPrice = ''
        }
      } else {
        itemData.formattedPrice = ''
      }

      return { id: nextBarId + index, data: itemData, priority: bar.length + index }
    })
    return items
  }

  const handleDragEnd = (result) => {
    if (!result.destination) return
    const newBars = Array.from(bar)
    const [movedBar] = newBars.splice(result.source.index, 1)
    newBars.splice(result.destination.index, 0, movedBar)
    const updatedBars = newBars.map((item, index) => ({
      ...item,
      priority: index,
    }))
    setBar(updatedBars)
  }

  const setBarDataAtIndex = useCallback(
    (index) => (data) => {
      setBar((prevBar) => {
        const newBars = [...prevBar]
        newBars[index] = { ...newBars[index], data }
        return newBars
      })
    },
    [setBar]
  )

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="barItems">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {bar.map((item, index) => (
                <Draggable key={item.id} draggableId={`bar-${item.id}`} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={{ ...provided.draggableProps.style, marginBottom: '16px' }}
                    >
                      <BarFormItem
                        key={item.id}
                        barNumber={item.id}
                        barData={item.data}
                        setBarData={setBarDataAtIndex(index)}
                        expanded={expandedBarId === item.id}
                        onChange={(isExpanded) => {
                          setExpandedBarId(isExpanded ? item.id : null)
                        }}
                        onDelete={() => handleDeleteBar(item.id)}
                        dragHandleProps={provided.dragHandleProps}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {/* Nueva área para "Agregar nuevo Trago" */}
      <Grid
        container
        width="100%"
        border="1px dashed black"
        sx={{
          borderRadius: '10px',
          marginTop: '16px',
          '&::before': { display: 'none' },
          padding: '8px 16px',
          alignItems: 'center',
        }}
      >
        <Grid item xs={12} sm={10}>
          <Typography variant="h6" sx={{ whiteSpace: 'nowrap' }}>
            Agregar nuevo trago
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2} container justifyContent="flex-end">
          <IconButton onClick={handleAddBar}>
            <AddCircleIcon style={{ height: '32px', width: '32px', color: '#fb8c00' }} />
          </IconButton>
        </Grid>
      </Grid>

      {/* Mostrar CSVUploader debajo */}
      <Box mt={2}>
        <CSVUploader handleFileUpload={handleFileUpload} templateLink="/assets/csv/BarTemplate.csv" />
      </Box>
    </>
  )
}

export default BarForm
