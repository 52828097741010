// src/hooks/useDeleteUser.js

import { usersInMemory } from '../memoryDataStore'

export const useDeleteUser = () => {
  const deleteUser = async (userId) => {
    const index = usersInMemory.findIndex((user) => user.id === userId)
    if (index !== -1) {
      usersInMemory.splice(index, 1)
    } else {
      throw new Error('Usuario no encontrado')
    }
  }

  return { deleteUser }
}
