import React from 'react'
import { Box, Button, ErrorComponent, LoadingSpinner, Typography } from '../../atoms'
import { useFetchEventsData } from '../../../hooks'
import { CustomerEventsTable } from '../index'
import { useNavigate } from 'react-router-dom'

function Events({ title, height }) {
  const [initialLoad, setInitialLoad] = React.useState(false)
  const { events, loading, error, refreshData } = useFetchEventsData()
  const history = useNavigate()

  if (!initialLoad) {
    refreshData()
    setInitialLoad(true)
  }

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorComponent message={error} />
  }

  function renderEvents() {
    if (events === null || events.length === 0) {
      return (
        <Box
          sx={{
            backgroundColor: '#092838',
            borderRadius: 2,
            height: '300px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="contained"
            color="warning"
            sx={{
              borderRadius: 2,
            }}
            onClick={() => history('/events/new')}
          >
            Crear un Evento
          </Button>
        </Box>
      )
    }
    return <CustomerEventsTable height={height} events={events} />
  }

  return (
    <Box>
      <Box mb={4}>
        <Typography variant="h4" color="white" fontWeight="bold">
          {title}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros.
        </Typography>
      </Box>
      {renderEvents()}
    </Box>
  )
}

export default Events
