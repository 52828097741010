import React, { memo, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteIcon from '@mui/icons-material/Delete'
import { FormTextField } from '../../atoms'
import FileUploader from '../../molecules/FileUploader/FileUploader'

const BarFormItem = memo(({ barNumber, barData, setBarData, expanded, onChange, onDelete, dragHandleProps }) => {
  const [errors, setErrors] = useState({})
  const [isEditingPrice, setIsEditingPrice] = useState(false)

  const validateField = (field, value) => {
    let error = ''
    if (field === 'name' && !value) {
      error = 'El nombre del trago es requerido'
    }
    if (field === 'price') {
      if (!value) {
        error = 'El precio del trago es requerido'
      } else if (isNaN(value)) {
        error = 'El precio del trago debe ser un número'
      } else if (Number(value) <= 0) {
        error = 'El precio del trago debe ser mayor que cero'
      }
    }
    if (field === 'quantity') {
      if (!value || isNaN(value)) {
        error = 'La cantidad disponible es requerida y debe ser un número'
      } else if (Number(value) <= 0) {
        error = 'La cantidad disponible debe ser mayor que cero'
      }
    }
    if (field === 'description') {
      if (value && value.length < 10) {
        error = 'La descripción debe tener al menos 10 caracteres'
      }
    }
    setErrors((prevErrors) => ({ ...prevErrors, [field]: error }))
  }

  const formatPrice = (value) => {
    if (!value) return ''
    const number = parseInt(value, 10)
    if (isNaN(number)) return ''
    return '$ ' + number.toLocaleString('es-CL')
  }

  const handleFieldChange = (field) => (e) => {
    let value = e.target.value
    if (field === 'price' || field === 'quantity') {
      value = value.replace(/\D/g, '')
    }

    const updatedData = { ...barData, [field]: value }
    setBarData(updatedData)
    validateField(field, value)
  }

  const handleFieldBlur = (field) => (e) => {
    if (field === 'price') {
      const value = barData.price
      const formattedValue = formatPrice(value)
      const updatedData = { ...barData, formattedPrice: formattedValue }
      setBarData(updatedData)
      setIsEditingPrice(false)
    }
  }

  const handleImageUpload = (croppedImage, imageName) => {
    const updatedData = { ...barData, image: croppedImage, imageName }
    setBarData(updatedData)
  }

  const handleDescriptionChange = (e) => {
    const value = e.target.value
    const updatedData = { ...barData, description: value }
    setBarData(updatedData)
    validateField('description', value)
  }

  return (
    <Accordion
      expanded={expanded}
      onChange={(event, isExpanded) => onChange(isExpanded)}
      sx={{
        width: '100%',
        border: '1px dashed black',
        borderRadius: '10px',
        '&::before': { display: 'none' },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${barNumber}-content`}
        id={`panel${barNumber}-header`}
        sx={{ padding: '0 16px' }}
        {...dragHandleProps}
      >
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">
              {barData.name ? barData.name : `Trago N°${barNumber}`}
              {barData.formattedPrice ? ` - ${barData.formattedPrice}` : ''}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              edge="end"
              onClick={(e) => {
                e.stopPropagation()
                onDelete()
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <Grid container spacing={2} sx={{ padding: '16px' }}>
          {/* Nombre del Trago */}
          <Grid item xs={12} md={6}>
            <FormTextField
              label="Nombre del Trago"
              value={barData.name || ''}
              onChange={handleFieldChange('name')}
              error={Boolean(errors.name)}
              helperText={errors.name}
            />
          </Grid>
          {/* Precio del Trago */}
          <Grid item xs={12} md={6}>
            <FormTextField
              label="Precio del Trago"
              value={isEditingPrice ? barData.price || '' : barData.formattedPrice || barData.price || ''}
              onChange={handleFieldChange('price')}
              onFocus={() => setIsEditingPrice(true)}
              onBlur={handleFieldBlur('price')}
              error={Boolean(errors.price)}
              helperText={errors.price}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            />
          </Grid>
          {/* Cantidad disponible */}
          <Grid item xs={12}>
            <FormTextField
              label="Cantidad disponible"
              value={barData.quantity || ''}
              onChange={handleFieldChange('quantity')}
              error={Boolean(errors.quantity)}
              helperText={errors.quantity}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            />
          </Grid>
          {/* Descripción del Trago */}
          <Grid item xs={12}>
            <FormTextField
              label="Descripción del Trago (Opcional)"
              onChange={handleDescriptionChange}
              multiline
              rows={4}
              error={Boolean(errors.description)}
              helperText={errors.description}
              value={barData.description || ''}
            />
          </Grid>
          {/* Campo para Seleccionar Imagen */}
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Imagen del Trago
            </Typography>
            <FileUploader
              handleImage={(croppedImage, imageName) => {
                handleImageUpload(croppedImage, imageName)
              }}
              data={{ rawImageName: barData.imageName }}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
})

export default BarFormItem
