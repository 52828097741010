import Drawer from '@mui/material/Drawer'
import { styled } from '@mui/material/styles'

export const SidenavStyle = styled(Drawer)(({ theme, ownerState }) => {
  const { palette, boxShadows, transitions, breakpoints, functions } = theme
  const { miniSidenav } = ownerState

  const sidebarWidth = 250
  const miniSidebarWidth = 96
  const { background } = palette
  const { xxl } = boxShadows
  const { pxToRem } = functions

  let backgroundValue = background.sidenav

  const drawerOpenStyles = () => ({
    position: 'fixed',
    top: 0,
    left: 0,
    borderRadius: 0,
    marginLeft: 0,
    marginTop: 0,
    height: '100%',
    background: backgroundValue,
    transform: 'translateX(0)',
    transition: transitions.create('transform', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up('xl')]: {
      boxShadow: xxl,
      marginBottom: 'inherit',
      width: sidebarWidth,
      transform: 'translateX(0)',
      transition: transitions.create(['width', 'background-color'], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
  })

  const drawerCloseStyles = () => ({
    position: 'fixed',
    top: 0,
    left: 0,
    borderRadius: 0,
    marginRight: 0,
    marginLeft: 0,
    marginTop: 0,
    height: '100%',
    background: backgroundValue,
    transform: 'translateX(0)',
    transition: transitions.create('transform', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up('xl')]: {
      boxShadow: xxl,
      marginBottom: 'inherit',
      width: miniSidebarWidth,
      overflowX: 'hidden',
      transform: 'translateX(0)',
      transition: transitions.create(['width', 'background-color'], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.shorter,
      }),
    },

    [breakpoints.down('xl')]: {
      width: miniSidebarWidth,
      overflowX: 'hidden',
      transform: 'translateX(0)',
      transition: transitions.create(['width', 'background-color'], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.shorter,
      }),
    },
  })

  return {
    '& .MuiDrawer-paper': {
      boxShadow: xxl,
      border: 'none',

      ...(miniSidenav ? drawerCloseStyles() : drawerOpenStyles()),
    },
  }
})
